/* timeline smart render */
// scrollable should be = true
// horizontal and vertical scroll -> dhx_timeline_scrollable_data
export default function (scheduler) {
  scheduler._timeline_smart_render = {
    _prepared_events_cache: null,
    _rendered_events_cache: [],
    _rendered_header_cache: [],
    _rendered_labels_cache: [],
    _rows_to_delete: [],
    _rows_to_add: [],
    _cols_to_delete: [],
    _cols_to_add: [],
    getViewPort: function getViewPort(scrollHelper, resizeHeight, scrollLeft, scrollTop) {
      // top/left/height/width of viewport with scroll pos
      var scrollBlock = scheduler.$container.querySelector(".dhx_cal_data");
      var coords = scrollBlock.getBoundingClientRect();
      var scrollableContainer = scheduler.$container.querySelector(".dhx_timeline_scrollable_data");

      if (scrollableContainer && scrollLeft === undefined) {
        scrollLeft = scrollHelper.getScrollValue(scrollableContainer);
      }

      if (scrollTop === undefined) {
        if (scrollableContainer) {
          scrollTop = scrollableContainer.scrollTop;
        } else {
          scrollTop = scrollBlock.scrollTop;
        }
      }

      var copy = {};

      for (var i in coords) {
        copy[i] = coords[i];
      }

      copy.scrollLeft = scrollLeft || 0;
      copy.scrollTop = scrollTop || 0;
      if (resizeHeight) coords.height = resizeHeight;
      return copy;
    },
    isInXViewPort: function isInXViewPort(item, viewPort) {
      var viewPortLeft = viewPort.scrollLeft;
      var viewPortRight = viewPort.width + viewPort.scrollLeft; // return true/false for item in/not in viewport on X axis

      return item.left < viewPortRight + 100 && item.right > viewPortLeft - 100; // +100 and -100 spreads viewport width
    },
    isInYViewPort: function isInYViewPort(item, viewPort) {
      var viewPortTop = viewPort.scrollTop;
      var viewPortBottom = viewPort.height + viewPort.scrollTop; // return true/false for item in/not in viewport on Y axis

      return item.top < viewPortBottom + 100 && item.bottom > viewPortTop - 100; // +100 and -100 spreads viewport height
    },
    getVisibleHeader: function getVisibleHeader(view, viewPort) {
      var curHeader = '';
      this._rendered_header_cache = [];

      for (var i in view._h_cols) {
        var col = view._h_cols[i];

        if (this.isInXViewPort({
          left: col.left,
          right: col.left + scheduler._cols[i]
        }, viewPort)) {
          var html = col.div.outerHTML;
          curHeader += html;

          this._rendered_header_cache.push(col.div.getAttribute("data-col-id"));
        }
      }

      return curHeader;
    },
    updateHeader: function updateHeader(view, viewPort, parent) {
      this._cols_to_delete = [];
      this._cols_to_add = [];
      var headers = scheduler.$container.querySelectorAll(".dhx_cal_header > div");
      var cells = headers[headers.length - 1].querySelectorAll(".dhx_scale_bar"); // take cells of bottom scale

      var visibleItems = [];

      for (var i = 0; i < cells.length; i++) {
        visibleItems.push(cells[i].getAttribute("data-col-id"));
      } // find new elements


      var res = this.getVisibleHeader(view, viewPort);
      if (!res) return;

      var renderers = this._rendered_header_cache.slice();

      var itemsToDel = [];

      for (var i = 0, len = visibleItems.length; i < len; i++) {
        var pos = renderers.indexOf(visibleItems[i]);

        if (pos > -1) {
          renderers.splice(pos, 1);
        } else {
          itemsToDel.push(visibleItems[i]);
        }
      }

      if (itemsToDel.length) {
        this._cols_to_delete = itemsToDel.slice();

        this._deleteHeaderCells(itemsToDel, view, parent);
      }

      if (renderers.length) {
        this._cols_to_add = renderers.slice();

        this._addHeaderCells(renderers, view, parent);
      }
    },
    _deleteHeaderCells: function _deleteHeaderCells(items, view, parent) {
      for (var i = 0; i < items.length; i++) {
        var item = parent.querySelector('[data-col-id="' + items[i] + '"]');

        if (item) {
          parent.removeChild(item);
        }
      }
    },
    _addHeaderCells: function _addHeaderCells(items, view, parent) {
      var html = '';

      for (var i = 0; i < items.length; i++) {
        html += view._h_cols[items[i]].div.outerHTML;
      }

      parent.insertAdjacentHTML('beforeEnd', html);
    },
    getVisibleLabels: function getVisibleLabels(view, viewPort) {
      if (!view._label_rows.length) return;
      var curLabelCol = '';
      this._rendered_labels_cache = [];

      for (var i = 0; i < view._label_rows.length; i++) {
        if (this.isInYViewPort({
          top: view._label_rows[i].top,
          bottom: view._label_rows[i].top + view._section_height[view.y_unit[i].key]
        }, viewPort)) {
          var html = view._label_rows[i].div;
          curLabelCol += html;

          this._rendered_labels_cache.push(i);
        }
      }

      return curLabelCol;
    },
    updateLabels: function updateLabels(view, viewPort, parent) {
      this._rows_to_delete = [];
      this._rows_to_add = [];

      var visibleItems = this._rendered_labels_cache.slice(); // is it realy no visible items? check it again


      if (!visibleItems.length) {
        this.getVisibleLabels(view, viewPort);
        visibleItems = this._rendered_labels_cache.slice();
      } // find new elements


      var res = this.getVisibleLabels(view, viewPort);
      if (!res) return;

      var renderers = this._rendered_labels_cache.slice();

      var itemsToDel = [];

      for (var i = 0, len = visibleItems.length; i < len; i++) {
        var pos = renderers.indexOf(visibleItems[i]);

        if (pos > -1) {
          renderers.splice(pos, 1);
        } else {
          itemsToDel.push(visibleItems[i]);
        }
      }

      if (itemsToDel.length) {
        this._rows_to_delete = itemsToDel.slice();

        this._deleteLabelCells(itemsToDel, view, parent);
      }

      if (renderers.length) {
        this._rows_to_add = renderers.slice();

        this._addLabelCells(renderers, view, parent);
      }
    },
    _deleteLabelCells: function _deleteLabelCells(items, view, parent) {
      for (var i = 0; i < items.length; i++) {
        var item = parent.querySelector('[data-row-index="' + items[i] + '"]');

        if (item) {
          parent.removeChild(item);
        }
      }
    },
    _addLabelCells: function _addLabelCells(items, view, parent) {
      var html = '';

      for (var i = 0; i < items.length; i++) {
        html += view._label_rows[items[i]].div;
      }

      parent.insertAdjacentHTML('beforeEnd', html);
    },
    clearPreparedEventsCache: function clearPreparedEventsCache() {
      this.cachePreparedEvents(null);
    },
    cachePreparedEvents: function cachePreparedEvents(events) {
      this._prepared_events_cache = events;
      this._prepared_events_coordinate_cache = events;
    },
    getPreparedEvents: function getPreparedEvents(view) {
      var evs;

      if (this._prepared_events_cache) {
        evs = this._prepared_events_cache;
      } else {
        evs = scheduler._prepare_timeline_events(view);
        evs.$coordinates = {};
        this.cachePreparedEvents(evs); //var x_start = scheduler._timeline_getX(evs[i][m], false, view);
        //		var x_end = scheduler._timeline_getX(evs[i][m], true, view);
      }

      return evs;
    },
    updateEvents: function updateEvents(view, viewPort) {
      var evs = this.getPreparedEvents(view);

      var visibleEvents = this._rendered_events_cache.slice();

      this._rendered_events_cache = [];
      var grid = scheduler.$container.querySelector('.dhx_cal_data .dhx_timeline_data_col');
      if (!grid) return;

      for (var i = 0; i < this._rendered_labels_cache.length; i++) {
        var row = this._rendered_labels_cache[i];
        var eventsToAdd = [];
        var visibleRowEvents = visibleEvents[row] ? visibleEvents[row].slice() : [];

        scheduler._timeline_calculate_event_positions.call(view, evs[row]);

        var renderers = scheduler._timeline_smart_render.getVisibleEventsForRow(view, viewPort, evs, row);

        for (var item = 0, lenRend = renderers.length; item < lenRend; item++) {
          var pos = visibleRowEvents.indexOf(renderers[item].id);

          if (pos > -1) {
            visibleRowEvents.splice(pos, 1);
          } else {
            eventsToAdd.push(renderers[item]);
          }
        }

        var line = grid.querySelector('[data-section-index="' + row + '"]');

        if (visibleRowEvents.length) {
          this._deleteEvents(visibleRowEvents, view, line);
        }

        if (eventsToAdd.length) {
          this._addEvents(eventsToAdd, view, line, row);
        }
      }

      scheduler._populate_timeline_rendered(scheduler.$container);

      view._matrix = evs;
    },
    _deleteEvents: function _deleteEvents(events, view, parent) {
      for (var i = 0; i < events.length; i++) {
        var event = parent.querySelector('[' + scheduler.config.event_attribute + '="' + events[i] + '"]');

        if (event) {
          if (!event.classList.contains('dhx_in_move')) parent.removeChild(event);
        }
      }
    },
    _addEvents: function _addEvents(events, view, parent, i) {
      // calculate height of all events but will render below only events in viewport
      var events_html = scheduler._timeline_update_events_html.call(view, events);

      parent.insertAdjacentHTML('beforeEnd', events_html);
    },
    getVisibleEventsForRow: function getVisibleEventsForRow(view, viewPort, evs, rowIndex) {
      // get events only for viewport
      var evsInViewport = [];

      if (view.render == "cell") {
        evsInViewport = evs;
      } else {
        var rowEvents = evs[rowIndex];

        if (rowEvents) {
          for (var m = 0, evLen = rowEvents.length; m < evLen; m++) {
            var event = rowEvents[m];
            var coordinateCacheKey = rowIndex + '_' + event.id;
            var xStart, xEnd;

            if (evs.$coordinates && evs.$coordinates[coordinateCacheKey]) {
              xStart = evs.$coordinates[coordinateCacheKey].xStart;
              xEnd = evs.$coordinates[coordinateCacheKey].xEnd;
            } else {
              xStart = scheduler._timeline_getX(event, false, view);
              xEnd = scheduler._timeline_getX(event, true, view);

              if (evs.$coordinates) {
                evs.$coordinates[coordinateCacheKey] = {
                  xStart: xStart,
                  xEnd: xEnd
                };
              }
            }

            if (scheduler._timeline_smart_render.isInXViewPort({
              left: xStart,
              right: xEnd
            }, viewPort)) {
              evsInViewport.push(event); // save to cache

              if (!this._rendered_events_cache[rowIndex]) this._rendered_events_cache[rowIndex] = [];

              this._rendered_events_cache[rowIndex].push(event.id);
            }
          }
        }
      }

      return evsInViewport;
    },
    getVisibleRowCellsHTML: function getVisibleRowCellsHTML(view, viewPort, stats, evs, i) {
      // full render for new row uses _rendered_header_cache
      // that contains currently visible cols
      var dataWrapper = '';
      var cellLeftPos;
      var visibleColumns = this._rendered_header_cache;

      for (var ind = 0; ind < visibleColumns.length; ind++) {
        var j = visibleColumns[ind];
        cellLeftPos = view._h_cols[j].left - view.dx;

        if (scheduler._ignores[j]) {
          if (view.render == "cell") {
            dataWrapper += scheduler._timeline_get_html_for_cell_ignores(stats);
          } else {
            dataWrapper += scheduler._timeline_get_html_for_bar_ignores();
          }
        } else {
          if (view.render == "cell") {
            dataWrapper += scheduler._timeline_get_html_for_cell(j, i, view, evs[i][j], stats, cellLeftPos);
          } else {
            dataWrapper += scheduler._timeline_get_html_for_bar(j, i, view, evs[i], cellLeftPos);
          }
        }
      }

      return dataWrapper;
    },
    getVisibleTimelineRowsHTML: function getVisibleTimelineRowsHTML(view, viewPort, evs, rowIndex) {
      var dataWrapper = '';

      var stats = scheduler._timeline_get_cur_row_stats(view, rowIndex);

      stats = scheduler._timeline_get_fit_events_stats(view, rowIndex, stats);
      var cachedRow = view._label_rows[rowIndex];
      var template = scheduler.templates[view.name + "_row_class"];
      var templateParams = {
        view: view,
        section: cachedRow.section,
        template: template
      }; // check vertical direction

      if (view.render == "cell") {
        dataWrapper += scheduler._timeline_get_html_for_cell_data_row(rowIndex, stats, cachedRow.top, cachedRow.section.key, templateParams);
        dataWrapper += this.getVisibleRowCellsHTML(view, viewPort, stats, evs, rowIndex);
        dataWrapper += '</div>';
      } else {
        //section 2
        dataWrapper += scheduler._timeline_get_html_for_bar_matrix_line(rowIndex, stats, cachedRow.top, cachedRow.section.key, templateParams); // section 3

        dataWrapper += scheduler._timeline_get_html_for_bar_data_row(stats, templateParams);
        dataWrapper += this.getVisibleRowCellsHTML(view, viewPort, stats, evs, rowIndex);
        dataWrapper += "</div></div>";
      }

      return dataWrapper;
    },
    updateGridRows: function updateGridRows(view, viewPort) {
      if (this._rows_to_delete.length) {
        this._deleteGridRows(this._rows_to_delete);
      }

      if (this._rows_to_add.length) {
        this._addGridRows(this._rows_to_add, view, viewPort);
      }
    },
    _deleteGridRows: function _deleteGridRows(items) {
      var parent = scheduler.$container.querySelector('.dhx_cal_data .dhx_timeline_data_col');
      if (!parent) return;

      for (var i = 0; i < items.length; i++) {
        var item = parent.querySelector('[data-section-index="' + items[i] + '"]');
        parent.removeChild(item);
      }

      this._rows_to_delete = [];
    },
    _addGridRows: function _addGridRows(items, view, viewPort) {
      var parent = scheduler.$container.querySelector('.dhx_cal_data .dhx_timeline_data_col');
      if (!parent) return;
      var evs = this.getPreparedEvents(view);
      var html = '';

      for (var i = 0; i < items.length; i++) {
        html += this.getVisibleTimelineRowsHTML(view, viewPort, evs, items[i]);
      }

      parent.insertAdjacentHTML('beforeEnd', html);

      for (var i = 0; i < items.length; i++) {
        scheduler._timeline_finalize_section_add(view, view.y_unit[items[i]].key, parent);
      }

      if (scheduler._mark_now) {
        scheduler._mark_now();
      }

      this._rows_to_add = [];
    },
    updateGridCols: function updateGridCols(view, viewPort) {
      var visibleHeaderColumns = this._rendered_header_cache;
      var renderedTimelineColumnsHash = {};
      var visibleHeaderColumnsHash = {};

      for (var i = 0; i < visibleHeaderColumns.length; i++) {
        visibleHeaderColumnsHash[visibleHeaderColumns[i]] = true;
      }

      var anyRow = scheduler.$container.querySelector(".dhx_timeline_data_row");

      if (anyRow) {
        var columns = anyRow.querySelectorAll("[data-col-id]");

        for (var i = 0; i < columns.length; i++) {
          renderedTimelineColumnsHash[columns[i].getAttribute("data-col-id")] = true;
        }
      }

      var shouldDelete = [],
          shouldRender = [];

      for (var i in renderedTimelineColumnsHash) {
        if (!visibleHeaderColumnsHash[i]) {
          shouldDelete.push(i);
        }
      }

      for (var i in visibleHeaderColumnsHash) {
        if (!renderedTimelineColumnsHash[i]) {
          shouldRender.push(i);
        }
      }

      if (shouldDelete.length) {
        this._deleteGridCols(shouldDelete, view);
      }

      if (shouldRender.length) {
        this._addGridCols(shouldRender, view, viewPort);
      }
    },
    _deleteGridCols: function _deleteGridCols(items, view) {
      var grid = scheduler.$container.querySelector('.dhx_cal_data .dhx_timeline_data_col');
      if (!grid) return;

      for (var r = 0; r < this._rendered_labels_cache.length; r++) {
        var parent;

        if (view.render == 'cell') {
          parent = grid.querySelector('[data-section-index="' + this._rendered_labels_cache[r] + '"]');
        } else {
          parent = grid.querySelector('[data-section-index="' + this._rendered_labels_cache[r] + '"] .dhx_timeline_data_row ');
        }

        if (parent) {
          for (var i = 0; i < items.length; i++) {
            var item = parent.querySelector('[data-col-id="' + items[i] + '"]');
            if (item) parent.removeChild(item);
          }
        }
      }

      this._cols_to_delete = [];
    },
    _addGridCols: function _addGridCols(items, view, viewPort) {
      var grid = scheduler.$container.querySelector('.dhx_cal_data .dhx_timeline_data_col');
      if (!grid) return;
      var evs = this.getPreparedEvents(view);

      for (var r = 0; r < this._rendered_labels_cache.length; r++) {
        var i = this._rendered_labels_cache[r];
        var html = '';

        var stats = scheduler._timeline_get_cur_row_stats(view, i);

        stats = scheduler._timeline_get_fit_events_stats(view, i, stats);
        var parent;

        if (view.render == 'cell') {
          parent = grid.querySelector('[data-section-index="' + i + '"]');
        } else {
          parent = grid.querySelector('[data-section-index="' + i + '"] .dhx_timeline_data_row');
        }

        if (parent) {
          for (var j = 0; j < items.length; j++) {
            var item = parent.querySelector('[data-col-id="' + items[j] + '"]');

            if (!item) {
              var cell = this.getVisibleGridCell(view, viewPort, stats, evs, i, items[j]);
              if (cell) html += cell;
            }
          }

          parent.insertAdjacentHTML('beforeEnd', html);
        }
      }

      this._cols_to_add = [];
    },
    getVisibleGridCell: function getVisibleGridCell(view, viewPort, stats, evs, i, cellIndex) {
      if (!view._h_cols[cellIndex]) return;
      var dataWrapper = '';
      var cellLeftPos = view._h_cols[cellIndex].left - view.dx;

      if (view.render == "cell") {
        if (scheduler._ignores[cellIndex]) {//dataWrapper += scheduler._timeline_get_html_for_cell_ignores(stats);
        } else {
          dataWrapper += scheduler._timeline_get_html_for_cell(cellIndex, i, view, evs[i][cellIndex], stats, cellLeftPos);
        }
      } else {
        if (scheduler._ignores[cellIndex]) {//dataWrapper += scheduler._timeline_get_html_for_bar_ignores();
        } else {
          dataWrapper += scheduler._timeline_get_html_for_bar(cellIndex, i, view, evs[i], cellLeftPos);
        }
      }

      return dataWrapper;
    }
  };
  /* timeline smart render end */
}