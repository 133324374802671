export default function (scheduler) {
  scheduler.$keyboardNavigation.KeyNavNode = function () {};

  scheduler.$keyboardNavigation.KeyNavNode.prototype = scheduler._compose(scheduler.$keyboardNavigation.EventHandler, {
    isValid: function isValid() {
      return true;
    },
    fallback: function fallback() {
      return null;
    },
    moveTo: function moveTo(element) {
      scheduler.$keyboardNavigation.dispatcher.setActiveNode(element);
    },
    compareTo: function compareTo(b) {
      // good enough comparison of two random objects
      if (!b) return false;

      for (var i in this) {
        if (!!this[i] != !!b[i]) return false;
        var canStringifyThis = !!(this[i] && this[i].toString);
        var canStringifyThat = !!(b[i] && b[i].toString);
        if (canStringifyThat != canStringifyThis) return false;

        if (!(canStringifyThat && canStringifyThis)) {
          if (b[i] != this[i]) return false;
        } else {
          if (b[i].toString() != this[i].toString()) return false;
        }
      }

      return true;
    },
    getNode: function getNode() {},
    focus: function focus() {
      var node = this.getNode();

      if (node) {
        node.setAttribute("tabindex", "-1"); //node.className += " scheduler_focused";

        if (node.focus) node.focus();
      }
    },
    blur: function blur() {
      var node = this.getNode();

      if (node) {
        node.setAttribute("tabindex", "-1"); //node.className = (node.className || "").replace(/ ?scheduler_focused/g, "");
      }
    }
  });
}