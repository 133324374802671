function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

export default function extend(scheduler) {
  (function () {
    // eslint-disable-next-line no-control-regex
    var htmlTags = new RegExp("<(?:.|\n)*?>", "gm");
    var extraSpaces = new RegExp(" +", "gm");

    function stripHTMLLite(htmlText) {
      return (htmlText + "").replace(htmlTags, " ").replace(extraSpaces, " ");
    }

    var singleQuotes = new RegExp("'", "gm");

    function escapeQuotes(text) {
      return (text + "").replace(singleQuotes, "&#39;");
    }

    scheduler._waiAria = {
      getAttributeString: function getAttributeString(attr) {
        var attributes = [" "];

        for (var i in attr) {
          if (typeof attr[i] != "function" && _typeof(attr[i]) != "object") {
            var text = escapeQuotes(stripHTMLLite(attr[i]));
            attributes.push(i + "='" + text + "'");
          }
        }

        attributes.push(" ");
        return attributes.join(" ");
      },
      setAttributes: function setAttributes(div, values) {
        for (var i in values) {
          div.setAttribute(i, stripHTMLLite(values[i]));
        }

        return div;
      },
      labelAttr: function labelAttr(div, content) {
        return this.setAttributes(div, {
          "aria-label": content
        });
      },
      label: function label(_label) {
        return scheduler._waiAria.getAttributeString({
          "aria-label": _label
        });
      },
      // day/week/units
      hourScaleAttr: function hourScaleAttr(div, content) {
        this.labelAttr(div, content);
      },
      monthCellAttr: function monthCellAttr(div, date) {
        this.labelAttr(div, scheduler.templates.day_date(date));
      },
      navBarDateAttr: function navBarDateAttr(div, content) {
        this.labelAttr(div, content);
      },
      dayHeaderAttr: function dayHeaderAttr(div, content) {
        this.labelAttr(div, content);
      },
      dayColumnAttr: function dayColumnAttr(div, date) {
        this.dayHeaderAttr(div, scheduler.templates.day_date(date));
      },
      headerButtonsAttributes: function headerButtonsAttributes(div, label) {
        return this.setAttributes(div, {
          "role": "button",
          "aria-label": label
        });
      },
      headerToggleState: function headerToggleState(div, isActive) {
        return this.setAttributes(div, {
          "aria-pressed": isActive ? "true" : "false"
        });
      },
      getHeaderCellAttr: function getHeaderCellAttr(dateString) {
        return scheduler._waiAria.getAttributeString({
          "aria-label": dateString
        });
      },
      eventAttr: function eventAttr(event, div) {
        this._eventCommonAttr(event, div);
      },
      _eventCommonAttr: function _eventCommonAttr(event, div) {
        div.setAttribute("aria-label", stripHTMLLite(scheduler.templates.event_text(event.start_date, event.end_date, event)));

        if (scheduler.config.readonly) {
          div.setAttribute("aria-readonly", true);
        }

        if (event.$dataprocessor_class) {
          div.setAttribute("aria-busy", true);
        }

        div.setAttribute("aria-selected", scheduler.getState().select_id == event.id ? "true" : "false");
      },
      setEventBarAttr: function setEventBarAttr(event, div) {
        this._eventCommonAttr(event, div);
      },
      _getAttributes: function _getAttributes(attributeSetter, arg) {
        var result = {
          setAttribute: function setAttribute(name, value) {
            this[name] = value;
          }
        };
        attributeSetter.apply(this, [arg, result]);
        return result;
      },
      eventBarAttrString: function eventBarAttrString(event) {
        return this.getAttributeString(this._getAttributes(this.setEventBarAttr, event));
      },
      agendaHeadAttrString: function agendaHeadAttrString() {
        return this.getAttributeString({
          role: "row"
        });
      },
      agendaHeadDateString: function agendaHeadDateString(label) {
        return this.getAttributeString({
          role: "columnheader",
          "aria-label": label
        });
      },
      agendaHeadDescriptionString: function agendaHeadDescriptionString(label) {
        return this.agendaHeadDateString(label);
      },
      agendaDataAttrString: function agendaDataAttrString() {
        return this.getAttributeString({
          role: "grid"
        });
      },
      agendaEventAttrString: function agendaEventAttrString(event) {
        var attrs = this._getAttributes(this._eventCommonAttr, event);

        attrs["role"] = "row";
        return this.getAttributeString(attrs);
      },
      agendaDetailsBtnString: function agendaDetailsBtnString() {
        return this.getAttributeString({
          "role": "button",
          "aria-label": scheduler.locale.labels.icon_details
        });
      },
      gridAttrString: function gridAttrString() {
        return this.getAttributeString({
          role: "grid"
        });
      },
      gridRowAttrString: function gridRowAttrString(event) {
        return this.agendaEventAttrString(event);
      },
      gridCellAttrString: function gridCellAttrString(event, column, value) {
        return this.getAttributeString({
          "role": "gridcell",
          "aria-label": [column.label === undefined ? column.id : column.label, ": ", value]
        });
      },
      mapAttrString: function mapAttrString() {
        return this.gridAttrString();
      },
      mapRowAttrString: function mapRowAttrString(event) {
        return this.gridRowAttrString(event);
      },
      mapDetailsBtnString: function mapDetailsBtnString() {
        return this.agendaDetailsBtnString();
      },
      minicalHeader: function minicalHeader(div, headerId) {
        this.setAttributes(div, {
          "id": headerId + "",
          "aria-live": "assertice",
          "aria-atomic": "true"
        });
      },
      minicalGrid: function minicalGrid(div, headerId) {
        this.setAttributes(div, {
          "aria-labelledby": headerId + "",
          "role": "grid"
        });
      },
      minicalRow: function minicalRow(div) {
        this.setAttributes(div, {
          "role": "row"
        });
      },
      minicalDayCell: function minicalDayCell(div, date) {
        var selected = date.valueOf() < scheduler._max_date.valueOf() && date.valueOf() >= scheduler._min_date.valueOf();

        this.setAttributes(div, {
          "role": "gridcell",
          "aria-label": scheduler.templates.day_date(date),
          "aria-selected": selected ? "true" : "false"
        });
      },
      minicalHeadCell: function minicalHeadCell(div) {
        this.setAttributes(div, {
          "role": "columnheader"
        });
      },
      weekAgendaDayCell: function weekAgendaDayCell(div, date) {
        var header = div.querySelector(".dhx_wa_scale_bar");
        var content = div.querySelector(".dhx_wa_day_data");
        var headerId = scheduler.uid() + "";
        this.setAttributes(header, {
          "id": headerId
        });
        this.setAttributes(content, {
          "aria-labelledby": headerId
        });
      },
      weekAgendaEvent: function weekAgendaEvent(div, event) {
        this.eventAttr(event, div);
      },
      lightboxHiddenAttr: function lightboxHiddenAttr(div) {
        div.setAttribute("aria-hidden", "true");
      },
      lightboxVisibleAttr: function lightboxVisibleAttr(div) {
        div.setAttribute("aria-hidden", "false");
      },
      lightboxSectionButtonAttrString: function lightboxSectionButtonAttrString(label) {
        return this.getAttributeString({
          "role": "button",
          "aria-label": label,
          "tabindex": "0"
        });
      },
      yearHeader: function yearHeader(div, headerId) {
        this.setAttributes(div, {
          "id": headerId + ""
        });
      },
      yearGrid: function yearGrid(div, headerId) {
        this.minicalGrid(div, headerId);
      },
      yearHeadCell: function yearHeadCell(div) {
        return this.minicalHeadCell(div);
      },
      yearRow: function yearRow(div) {
        return this.minicalRow(div);
      },
      yearDayCell: function yearDayCell(div) {
        this.setAttributes(div, {
          "role": "gridcell"
        });
      },
      lightboxAttr: function lightboxAttr(div) {
        div.setAttribute("role", "dialog");
        div.setAttribute("aria-hidden", "true");
        div.firstChild.setAttribute("role", "heading");
      },
      lightboxButtonAttrString: function lightboxButtonAttrString(buttonName) {
        return this.getAttributeString({
          "role": "button",
          "aria-label": scheduler.locale.labels[buttonName],
          "tabindex": "0"
        });
      },
      eventMenuAttrString: function eventMenuAttrString(iconName) {
        return this.getAttributeString({
          "role": "button",
          "aria-label": scheduler.locale.labels[iconName]
        });
      },
      lightboxHeader: function lightboxHeader(div, headerText) {
        div.setAttribute("aria-label", headerText);
      },
      lightboxSelectAttrString: function lightboxSelectAttrString(time_option) {
        var label = "";

        switch (time_option) {
          case "%Y":
            label = scheduler.locale.labels.year;
            break;

          case "%m":
            label = scheduler.locale.labels.month;
            break;

          case "%d":
            label = scheduler.locale.labels.day;
            break;

          case "%H:%i":
            label = scheduler.locale.labels.hour + " " + scheduler.locale.labels.minute;
            break;

          default:
            break;
        }

        return scheduler._waiAria.getAttributeString({
          "aria-label": label
        });
      },
      messageButtonAttrString: function messageButtonAttrString(buttonLabel) {
        return "tabindex='0' role='button' aria-label='" + buttonLabel + "'";
      },
      messageInfoAttr: function messageInfoAttr(div) {
        div.setAttribute("role", "alert"); //div.setAttribute("tabindex", "-1");
      },
      messageModalAttr: function messageModalAttr(div, uid) {
        div.setAttribute("role", "dialog");

        if (uid) {
          div.setAttribute("aria-labelledby", uid);
        } //	div.setAttribute("tabindex", "-1");

      },
      quickInfoAttr: function quickInfoAttr(div) {
        div.setAttribute("role", "dialog");
      },
      quickInfoHeaderAttrString: function quickInfoHeaderAttrString() {
        return " role='heading' ";
      },
      quickInfoHeader: function quickInfoHeader(div, header) {
        div.setAttribute("aria-label", header);
      },
      quickInfoButtonAttrString: function quickInfoButtonAttrString(label) {
        return scheduler._waiAria.getAttributeString({
          "role": "button",
          "aria-label": label,
          "tabindex": "0"
        });
      },
      tooltipAttr: function tooltipAttr(div) {
        div.setAttribute("role", "tooltip");
      },
      tooltipVisibleAttr: function tooltipVisibleAttr(div) {
        div.setAttribute("aria-hidden", "false");
      },
      tooltipHiddenAttr: function tooltipHiddenAttr(div) {
        div.setAttribute("aria-hidden", "true");
      }
    };

    function isDisabled() {
      return !scheduler.config.wai_aria_attributes;
    }

    for (var i in scheduler._waiAria) {
      scheduler._waiAria[i] = function (payload) {
        return function () {
          if (isDisabled()) {
            return " ";
          }

          return payload.apply(this, arguments);
        };
      }(scheduler._waiAria[i]);
    }
  })();
}