function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

import createEventScope from './utils/scoped_event';
export default function extend(scheduler) {
  var domEvents = createEventScope();
  scheduler.event = domEvents.attach;
  scheduler.eventRemove = domEvents.detach;
  scheduler._eventRemoveAll = domEvents.detachAll;
  scheduler._createDomEventScope = domEvents.extend;

  scheduler._trim = function (str) {
    var func = String.prototype.trim || function () {
      return this.replace(/^\s+|\s+$/g, "");
    };

    return func.apply(str);
  };

  scheduler._isDate = function (obj) {
    if (obj && _typeof(obj) == "object") {
      return !!(obj.getFullYear && obj.getMonth && obj.getDate);
    } else {
      return false;
    }
  };

  scheduler._isObject = function (obj) {
    return obj && _typeof(obj) == "object";
  };
}