/* eslint-disable  */
export default function dhtmlxError(type, name, params) {
  if (!this.catches) this.catches = [];
  return this;
}

dhtmlxError.prototype.catchError = function (type, func_name) {
  this.catches[type] = func_name;
};

dhtmlxError.prototype.throwError = function (type, name, params) {
  if (this.catches[type]) return this.catches[type](type, name, params);
  if (this.catches["ALL"]) return this.catches["ALL"](type, name, params);
  global.alert("Error type: " + arguments[0] + "\nDescription: " + arguments[1]);
  return null;
};