export default {
  date: {
    month_full: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
    month_short: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    day_full: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
    day_short: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
  },
  labels: {
    dhx_cal_today_button: "Today",
    day_tab: "Day",
    week_tab: "Week",
    month_tab: "Month",
    new_event: "New event",
    icon_save: "Save",
    icon_cancel: "Cancel",
    icon_details: "Details",
    icon_edit: "Edit",
    icon_delete: "Delete",
    confirm_closing: "",
    //Your changes will be lost, are your sure ?
    confirm_deleting: "Event will be deleted permanently, are you sure?",
    section_description: "Description",
    section_time: "Time period",
    full_day: "Full day",

    /*recurring events*/
    confirm_recurring: "Do you want to edit the whole set of repeated events?",
    section_recurring: "Repeat event",
    button_recurring: "Disabled",
    button_recurring_open: "Enabled",
    button_edit_series: "Edit series",
    button_edit_occurrence: "Edit occurrence",

    /*agenda view extension*/
    agenda_tab: "Agenda",
    date: "Date",
    description: "Description",

    /*year view extension*/
    year_tab: "Year",

    /* week agenda extension */
    week_agenda_tab: "Agenda",

    /*grid view extension*/
    grid_tab: "Grid",

    /* touch tooltip*/
    drag_to_create: "Drag to create",
    drag_to_move: "Drag to move",

    /* dhtmlx message default buttons */
    message_ok: "OK",
    message_cancel: "Cancel",

    /* wai aria labels for non-text controls */
    next: "Next",
    prev: "Previous",
    year: "Year",
    month: "Month",
    day: "Day",
    hour: "Hour",
    minute: "Minute",

    /* recurring event components */
    repeat_radio_day: "Daily",
    //name="repeat" value="day"
    repeat_radio_week: "Weekly",
    //name="repeat" value="week
    repeat_radio_month: "Monthly",
    repeat_radio_year: "Yearly",
    repeat_radio_day_type: "Every",
    repeat_text_day_count: "day",
    repeat_radio_day_type2: "Every workday",
    repeat_week: " Repeat every",
    repeat_text_week_count: "week next days:",
    repeat_radio_month_type: "Repeat",
    repeat_radio_month_start: "On",
    repeat_text_month_day: "day every",
    repeat_text_month_count: "month",
    repeat_text_month_count2_before: "every",
    repeat_text_month_count2_after: "month",
    repeat_year_label: "On",
    select_year_day2: "of",
    repeat_text_year_day: "day",
    select_year_month: "month",
    repeat_radio_end: "No end date",
    repeat_text_occurences_count: "occurrences",
    repeat_radio_end2: "After",
    repeat_radio_end3: "End by",
    month_for_recurring: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
    day_for_recurring: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"] //

  }
};