function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

export default function extend(scheduler) {
  scheduler.json = scheduler._parsers.json = {
    canParse: function canParse(data) {
      if (data && _typeof(data) === "object") {
        return true;
      } else if (typeof data === "string") {
        try {
          var result = JSON.parse(data);
          return Object.prototype.toString.call(result) === '[object Object]' || Object.prototype.toString.call(result) === '[object Array]';
        } catch (err) {
          return false;
        }
      }

      return false;
    },
    parse: function parse(data) {
      var events = [];

      if (typeof data == "string") {
        data = JSON.parse(data);
      }

      if (Object.prototype.toString.call(data) === '[object Array]') {
        events = data;
      } else {
        if (data) {
          if (data.events) {
            events = data.events;
          } else if (data.data) {
            events = data.data;
          }
        }
      }

      events = events || [];

      if (data.dhx_security) {
        if (window.dhtmlx) {
          window.dhtmlx.security_key = data.dhx_security;
        }

        scheduler.security_key = data.dhx_security;
      }

      var collections = data && data.collections ? data.collections : {};
      var collections_loaded = false;

      for (var key in collections) {
        if (collections.hasOwnProperty(key)) {
          collections_loaded = true;
          var collection = collections[key];
          var arr = scheduler.serverList[key];

          if (!arr) {
            scheduler.serverList[key] = arr = [];
          }

          arr.splice(0, arr.length); //clear old options

          for (var j = 0; j < collection.length; j++) {
            var option = collection[j];
            var obj = {
              key: option.value,
              label: option.label
            }; // resulting option object

            for (var option_key in option) {
              if (option.hasOwnProperty(option_key)) {
                if (option_key == "value" || option_key == "label") continue;
                obj[option_key] = option[option_key]; // obj['value'] = option['value']
              }
            }

            arr.push(obj);
          }
        }
      }

      if (collections_loaded) scheduler.callEvent("onOptionsLoad", []);
      var evs = [];

      for (var i = 0; i < events.length; i++) {
        var event = events[i];

        scheduler._init_event(event);

        evs.push(event);
      }

      return evs;
    }
  };
}