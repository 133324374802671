export default function (scheduler) {
  scheduler.$keyboardNavigation.attachSchedulerHandlers = function () {
    var dispatcher = scheduler.$keyboardNavigation.dispatcher;

    var keyDownHandler = function keyDownHandler(e) {
      if (!scheduler.config.key_nav) return;
      return dispatcher.keyDownHandler(e);
    };

    var focusHandler = function focusHandler() {
      dispatcher.keepScrollPosition(function () {
        dispatcher.focusGlobalNode();
      });
    };

    var waitCall;
    scheduler.attachEvent("onDataRender", function () {
      if (!scheduler.config.key_nav) return;
      if (!(dispatcher.isEnabled() && !scheduler.getState().editor_id)) return;
      clearTimeout(waitCall);
      waitCall = setTimeout(function () {
        if (!dispatcher.isEnabled()) dispatcher.enable();
        reFocusActiveNode();
      });
    });

    var reFocusActiveNode = function reFocusActiveNode() {
      if (!dispatcher.isEnabled()) return;
      var activeNode = dispatcher.getActiveNode();
      if (!activeNode) return;

      if (!activeNode.isValid()) {
        activeNode = activeNode.fallback();
      }

      if (!activeNode || activeNode instanceof scheduler.$keyboardNavigation.MinicalButton || activeNode instanceof scheduler.$keyboardNavigation.MinicalCell) return;
      dispatcher.keepScrollPosition(function () {
        activeNode.focus(true);
      });
    };

    scheduler.attachEvent("onSchedulerReady", function () {
      var container = scheduler.$container;
      scheduler.eventRemove(document, "keydown", keyDownHandler);
      scheduler.eventRemove(container, "mousedown", mousedownHandler);
      scheduler.eventRemove(container, "focus", focusHandler);

      if (scheduler.config.key_nav) {
        scheduler.event(document, "keydown", keyDownHandler);
        scheduler.event(container, "mousedown", mousedownHandler);
        scheduler.event(container, "focus", focusHandler);
        container.setAttribute("tabindex", "0");
      } else {
        container.removeAttribute("tabindex");
      }
    });

    function mousedownHandler(e) {
      if (!scheduler.config.key_nav) return true;
      var dataAreaClick = scheduler.$keyboardNavigation.isChildOf(e.target || e.srcElement, scheduler.$container.querySelector(".dhx_cal_data"));
      var pos = scheduler.getActionData(e);
      var focusNode;

      if (scheduler._locate_event(e.target || e.srcElement)) {
        focusNode = new scheduler.$keyboardNavigation.Event(scheduler._locate_event(e.target || e.srcElement));
      } else if (dataAreaClick) {
        focusNode = new scheduler.$keyboardNavigation.TimeSlot();

        if (pos.date && dataAreaClick) {
          focusNode = focusNode.nextSlot(new scheduler.$keyboardNavigation.TimeSlot(pos.date, null, pos.section));
        }
      }

      if (focusNode) {
        if (!dispatcher.isEnabled()) {
          dispatcher.activeNode = focusNode;
        } else {
          if (pos.date && dataAreaClick) {
            dispatcher.delay(function () {
              dispatcher.setActiveNode(focusNode);
            });
          }
        }
      }
    }

    function focusEvent(evNode) {
      if (!scheduler.config.key_nav) return;
      if (!dispatcher.isEnabled()) return;
      var prevState = evNode;
      var focusNode = new scheduler.$keyboardNavigation.Event(prevState.eventId);

      if (!focusNode.isValid()) {
        var lastStart = focusNode.start || prevState.start;
        var lastEnd = focusNode.end || prevState.end;
        var lastSection = focusNode.section || prevState.section;
        focusNode = new scheduler.$keyboardNavigation.TimeSlot(lastStart, lastEnd, lastSection);

        if (!focusNode.isValid()) {
          focusNode = new scheduler.$keyboardNavigation.TimeSlot();
        }
      }

      dispatcher.setActiveNode(focusNode);
      var node = dispatcher.getActiveNode();

      if (node && node.getNode && document.activeElement != node.getNode()) {
        dispatcher.focusNode(dispatcher.getActiveNode());
      }
    }

    var updateEvent = scheduler.updateEvent;

    scheduler.updateEvent = function (id) {
      var res = updateEvent.apply(this, arguments);

      if (scheduler.config.key_nav && dispatcher.isEnabled()) {
        if (scheduler.getState().select_id == id) {
          var element = new scheduler.$keyboardNavigation.Event(id);

          if (!scheduler.getState().lightbox_id) {
            focusEvent(element);
          }
        }
      }

      return res;
    };

    scheduler.attachEvent("onEventDeleted", function (id) {
      if (!scheduler.config.key_nav) return true;

      if (dispatcher.isEnabled()) {
        var activeNode = dispatcher.getActiveNode();

        if (activeNode.eventId == id) {
          dispatcher.setActiveNode(new scheduler.$keyboardNavigation.TimeSlot());
        }
      }

      return true;
    });
    scheduler.attachEvent("onClearAll", function () {
      if (!scheduler.config.key_nav) return true;

      if (dispatcher.isEnabled()) {
        if (dispatcher.getActiveNode() instanceof scheduler.$keyboardNavigation.Event) {
          dispatcher.setActiveNode(new scheduler.$keyboardNavigation.TimeSlot());
        }
      }
    });
  };
}