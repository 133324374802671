export default {
  date: {
    month_full: ["Січень", "Лютий", "Березень", "Квітень", "Травень", "Червень", "Липень", "Серпень", "Вересень", "Жовтень", "Листопад", "Грудень"],
    month_short: ["Січ", "Лют", "Бер", "Кві", "Тра", "Чер", "Лип", "Сер", "Вер", "Жов", "Лис", "Гру"],
    day_full: ["Неділя", "Понеділок", "Вівторок", "Середа", "Четвер", "П'ятниця", "Субота"],
    day_short: ["Нед", "Пон", "Вів", "Сер", "Чет", "Птн", "Суб"]
  },
  labels: {
    dhx_cal_today_button: "Сьогодні",
    day_tab: "День",
    week_tab: "Тиждень",
    month_tab: "Місяць",
    new_event: "Нова подія",
    icon_save: "Зберегти",
    icon_cancel: "Відміна",
    icon_details: "Деталі",
    icon_edit: "Редагувати",
    icon_delete: "Вилучити",
    confirm_closing: "",
    //Ваші зміни втратяться. Ви впевнені ?
    confirm_deleting: "Подія вилучиться назавжди. Ви впевнені?",
    section_description: "Опис",
    section_time: "Часовий проміжок",
    full_day: "Весь день",

    /*recurring events*/
    confirm_recurring: "Хочете редагувати весь перелік повторюваних подій?",
    section_recurring: "Повторювана подія",
    button_recurring: "Відключено",
    button_recurring_open: "Включено",
    button_edit_series: "Редагувати серію",
    button_edit_occurrence: "Редагувати примірник",

    /*agenda view extension*/
    agenda_tab: "Перелік",
    date: "Дата",
    description: "Опис",

    /*year view extension*/
    year_tab: "Рік",

    /*week agenda view extension*/
    week_agenda_tab: "Перелік",

    /*grid view extension*/
    grid_tab: "Таблиця",

    /* touch tooltip*/
    drag_to_create: "Drag to create",
    drag_to_move: "Drag to move",

    /* dhtmlx message default buttons */
    message_ok: "OK",
    message_cancel: "Cancel",

    /* wai aria labels for non-text controls */
    next: "Next",
    prev: "Previous",
    year: "Year",
    month: "Month",
    day: "Day",
    hour: "Hour",
    minute: "Minute",

    /* recurring event components */
    repeat_radio_day: "День",
    repeat_radio_week: "Тиждень",
    repeat_radio_month: "Місяць",
    repeat_radio_year: "Рік",
    repeat_radio_day_type: "Кожний",
    repeat_text_day_count: "день",
    repeat_radio_day_type2: "Кожний робочий день",
    repeat_week: " Повторювати кожен",
    repeat_text_week_count: "тиждень , по:",
    repeat_radio_month_type: "Повторювати",
    repeat_radio_month_start: "",
    repeat_text_month_day: " числа кожний ",
    repeat_text_month_count: "місяць",
    repeat_text_month_count2_before: "кожен ",
    repeat_text_month_count2_after: "місяць",
    repeat_year_label: "",
    select_year_day2: "",
    repeat_text_year_day: "день",
    select_year_month: "",
    repeat_radio_end: "Без дати закінчення",
    repeat_text_occurences_count: "повторень",
    repeat_radio_end3: "До ",
    repeat_radio_end2: "",
    month_for_recurring: ["січня", "лютого", "березня", "квітня", "травня", "червня", "липня", "серпня", "вересня", "жовтня", "листопада", "грудня"],
    day_for_recurring: ["Неділям", "Понеділкам", "Вівторкам", "Середам", "Четвергам", "П'ятницям", "Суботам"]
  }
};