export default function (scheduler) {
  scheduler.$keyboardNavigation.Event = function (id) {
    this.eventId = null;

    if (scheduler.getEvent(id)) {
      var ev = scheduler.getEvent(id);
      this.start = new Date(ev.start_date);
      this.end = new Date(ev.end_date);
      this.section = this._getSection(ev);
      this.eventId = id;
    }
  };

  scheduler.$keyboardNavigation.Event.prototype = scheduler._compose(scheduler.$keyboardNavigation.KeyNavNode, {
    _getNodes: function _getNodes() {
      return Array.prototype.slice.call(scheduler.$container.querySelectorAll("[" + scheduler.config.event_attribute + "]"));
    },
    _modes: scheduler.$keyboardNavigation.SchedulerNode.prototype._modes,
    getMode: scheduler.$keyboardNavigation.SchedulerNode.prototype.getMode,
    _handlers: null,
    isValid: function isValid() {
      return !!(scheduler.getEvent(this.eventId) && this.getNode());
    },
    fallback: function fallback() {
      var eventNode = this._getNodes()[0];

      var defaultElement = null;

      if (!eventNode || !scheduler._locate_event(eventNode)) {
        defaultElement = new scheduler.$keyboardNavigation.TimeSlot();
      } else {
        var id = scheduler._locate_event(eventNode);

        defaultElement = new scheduler.$keyboardNavigation.Event(id);
      }

      return defaultElement;
    },
    isScrolledIntoView: function isScrolledIntoView(el) {
      var eventBox = el.getBoundingClientRect();
      var viewPort = scheduler.$container.querySelector(".dhx_cal_data").getBoundingClientRect();

      if (eventBox.bottom < viewPort.top || eventBox.top > viewPort.bottom) {
        return false;
      }

      return true;
    },
    getNode: function getNode() {
      var idSelector = "[" + scheduler.config.event_attribute + "='" + this.eventId + "']";
      var inlineEditor = scheduler.$keyboardNavigation.dispatcher.getInlineEditor(this.eventId);

      if (inlineEditor) {
        // is inline editor visible
        return inlineEditor;
      } else {
        if (scheduler.isMultisectionEvent && scheduler.isMultisectionEvent(scheduler.getEvent(this.eventId))) {
          var nodes = scheduler.$container.querySelectorAll(idSelector);

          for (var i = 0; i < nodes.length; i++) {
            if (this.isScrolledIntoView(nodes[i])) {
              return nodes[i];
            }
          }

          return nodes[0];
        } else {
          return scheduler.$container.querySelector(idSelector);
        }
      }
    },
    focus: function focus() {
      var event = scheduler.getEvent(this.eventId);
      var calendar = scheduler.getState();

      if (event.start_date.valueOf() > calendar.max_date.valueOf() || event.end_date.valueOf() <= calendar.min_date.valueOf()) {
        scheduler.setCurrentView(event.start_date);
      }

      var node = this.getNode();

      if (this.isScrolledIntoView(node)) {
        scheduler.$keyboardNavigation.dispatcher.keepScrollPosition(function () {
          scheduler.$keyboardNavigation.KeyNavNode.prototype.focus.apply(this);
        }.bind(this));
      } else {
        scheduler.$keyboardNavigation.KeyNavNode.prototype.focus.apply(this);
      }
    },
    blur: function blur() {
      scheduler.$keyboardNavigation.KeyNavNode.prototype.blur.apply(this);
    },
    _getSection: function _getSection(ev) {
      var section = null;
      var mode = scheduler.getState().mode;

      if (scheduler.matrix && scheduler.matrix[mode]) {
        var timeline = scheduler.matrix[scheduler.getState().mode];
        section = ev[timeline.y_property];
      } else if (scheduler._props && scheduler._props[mode]) {
        var unit = scheduler._props[mode];
        section = ev[unit.map_to];
      }

      return section;
    },
    _moveToSlot: function _moveToSlot(dir) {
      var ev = scheduler.getEvent(this.eventId);

      if (ev) {
        var section = this._getSection(ev);

        var slot = new scheduler.$keyboardNavigation.TimeSlot(ev.start_date, null, section);
        this.moveTo(slot.nextSlot(slot, dir));
      } else {
        this.moveTo(new scheduler.$keyboardNavigation.TimeSlot());
      }
    },
    keys: {
      "left": function left() {
        this._moveToSlot("left");
      },
      "right": function right() {
        this._moveToSlot("right");
      },
      "down": function down() {
        if (this.getMode() == this._modes.list) {
          scheduler.$keyboardNavigation.SchedulerNode.prototype.nextEventHandler();
        } else {
          this._moveToSlot("down");
        }
      },
      "space": function space() {
        var node = this.getNode();

        if (node && node.click) {
          node.click();
        } else {
          this.moveTo(new scheduler.$keyboardNavigation.TimeSlot());
        }
      },
      "up": function up() {
        if (this.getMode() == this._modes.list) {
          scheduler.$keyboardNavigation.SchedulerNode.prototype.prevEventHandler();
        } else {
          this._moveToSlot("up");
        }
      },
      "delete": function _delete() {
        if (scheduler.getEvent(this.eventId)) {
          scheduler._click.buttons["delete"](this.eventId);
        } else {
          this.moveTo(new scheduler.$keyboardNavigation.TimeSlot());
        }
      },
      // open lightbox
      "enter": function enter() {
        if (scheduler.getEvent(this.eventId)) {
          scheduler.showLightbox(this.eventId);
        } else {
          this.moveTo(new scheduler.$keyboardNavigation.TimeSlot());
        }
      }
    }
  });
  scheduler.$keyboardNavigation.Event.prototype.bindAll(scheduler.$keyboardNavigation.Event.prototype.keys);
}