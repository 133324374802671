function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

import global from "../global";
import dhtmlxErrorConstructor from "./dhtmlx_error";
export default function extend() {
  /* eslint-disable  */
  if (!global.dhtmlx) {
    global.dhtmlx = function (obj) {
      for (var a in obj) {
        dhtmlx[a] = obj[a];
      }

      return dhtmlx; //simple singleton
    };
  }

  var dhtmlx = global.dhtmlx;

  dhtmlx.extend_api = function (name, map, ext) {
    var t = global[name];
    if (!t) return; //component not defined

    global[name] = function (obj) {
      var that;

      if (obj && _typeof(obj) == "object" && !obj.tagName) {
        that = t.apply(this, map._init ? map._init(obj) : arguments); //global settings

        for (var a in dhtmlx) {
          if (map[a]) this[map[a]](dhtmlx[a]);
        } //local settings


        for (var a in obj) {
          if (map[a]) this[map[a]](obj[a]);else if (a.indexOf("on") === 0) {
            this.attachEvent(a, obj[a]);
          }
        }
      } else that = t.apply(this, arguments);

      if (map._patch) map._patch(this);
      return that || this;
    };

    global[name].prototype = t.prototype;
    if (ext) dhtmlXHeir(global[name].prototype, ext);
  }; // TODO: remove


  global.dhtmlxAjax = {
    get: function get(url, callback) {
      var t = new dtmlXMLLoaderObject(true);
      t.async = arguments.length < 3;
      t.waitCall = callback;
      t.loadXML(url);
      return t;
    },
    post: function post(url, _post, callback) {
      var t = new dtmlXMLLoaderObject(true);
      t.async = arguments.length < 4;
      t.waitCall = callback;
      t.loadXML(url, true, _post);
      return t;
    },
    getSync: function getSync(url) {
      return this.get(url, null, true);
    },
    postSync: function postSync(url, post) {
      return this.post(url, post, null, true);
    }
  }; // TODO: remove

  /**
   *	 @desc: xmlLoader object
  	*	 @type: private
  	*	 @param: funcObject - xml parser function
  	*	 @param: object - jsControl object
  	*	 @param: async - sync/async mode (async by default)
  	*	 @param: rSeed - enable/disable random seed ( prevent IE caching)
  	*	 @topic: 0
  	*/

  function dtmlXMLLoaderObject(funcObject, dhtmlObject, async, rSeed) {
    this.xmlDoc = "";
    if (typeof async != "undefined") this.async = async;else this.async = true;
    this.onloadAction = funcObject || null;
    this.mainObject = dhtmlObject || null;
    this.waitCall = null;
    this.rSeed = rSeed || false;
    return this;
  }

  global.dtmlXMLLoaderObject = dtmlXMLLoaderObject;
  dtmlXMLLoaderObject.count = 0;
  /**
   *	 @desc: xml loading handler
  	*	 @type: private
  	*	 @param: dtmlObject - xmlLoader object
  	*	 @topic: 0
  	*/

  dtmlXMLLoaderObject.prototype.waitLoadFunction = function (dhtmlObject) {
    var once = true;

    this.check = function () {
      if (dhtmlObject && dhtmlObject.onloadAction) {
        if (!dhtmlObject.xmlDoc.readyState || dhtmlObject.xmlDoc.readyState == 4) {
          if (!once) return;
          once = false; //IE 5 fix

          dtmlXMLLoaderObject.count++;
          if (typeof dhtmlObject.onloadAction == "function") dhtmlObject.onloadAction(dhtmlObject.mainObject, null, null, null, dhtmlObject);

          if (dhtmlObject.waitCall) {
            dhtmlObject.waitCall.call(this, dhtmlObject);
            dhtmlObject.waitCall = null;
          }
        }
      }
    };

    return this.check;
  };
  /**
   *	 @desc: return XML top node
  	*	 @param: tagName - top XML node tag name (not used in IE, required for Safari and Mozilla)
  	*	 @type: private
  	*	 @returns: top XML node
  	*	 @topic: 0
  	*/


  dtmlXMLLoaderObject.prototype.getXMLTopNode = function (tagName, oldObj) {
    var z;

    if (this.xmlDoc.responseXML) {
      var temp = this.xmlDoc.responseXML.getElementsByTagName(tagName);
      if (temp.length === 0 && tagName.indexOf(":") != -1) var temp = this.xmlDoc.responseXML.getElementsByTagName(tagName.split(":")[1]);
      z = temp[0];
    } else z = this.xmlDoc.documentElement;

    if (z) {
      this._retry = false;
      return z;
    }

    if (!this._retry && _isIE) {
      this._retry = true;
      var oldObj = this.xmlDoc;
      this.loadXMLString(this.xmlDoc.responseText.replace(/^[\s]+/, ""), true);
      return this.getXMLTopNode(tagName, oldObj);
    }

    dhtmlxError.throwError("LoadXML", "Incorrect XML", [oldObj || this.xmlDoc, this.mainObject]);
    return document.createElement("div");
  };
  /**
   *	 @desc: load XML from string
  	*	 @type: private
  	*	 @param: xmlString - xml string
  	*	 @topic: 0
  	*/


  dtmlXMLLoaderObject.prototype.loadXMLString = function (xmlString, silent) {
    if (!_isIE) {
      var parser = new DOMParser();
      this.xmlDoc = parser.parseFromString(xmlString, "text/xml");
    } else {
      this.xmlDoc = new ActiveXObject("Microsoft.XMLDOM");
      this.xmlDoc.async = this.async;

      this.xmlDoc.onreadystatechange = function () {};

      this.xmlDoc["loadXM" + "L"](xmlString);
    }

    if (silent) return;
    if (this.onloadAction) this.onloadAction(this.mainObject, null, null, null, this);

    if (this.waitCall) {
      this.waitCall();
      this.waitCall = null;
    }
  };
  /**
   *	 @desc: load XML
  	*	 @type: private
  	*	 @param: filePath - xml file path
  	*	 @param: postMode - send POST request
  	*	 @param: postVars - list of vars for post request
  	*	 @topic: 0
  	*/


  dtmlXMLLoaderObject.prototype.loadXML = function (filePath, postMode, postVars, rpc) {
    if (this.rSeed) filePath += (filePath.indexOf("?") != -1 ? "&" : "?") + "a_dhx_rSeed=" + new Date().valueOf();
    this.filePath = filePath;
    if (!_isIE && global.XMLHttpRequest) this.xmlDoc = new XMLHttpRequest();else {
      this.xmlDoc = new ActiveXObject("Microsoft.XMLHTTP");
    }
    if (this.async) this.xmlDoc.onreadystatechange = new this.waitLoadFunction(this);
    if (typeof postMode == "string") this.xmlDoc.open(postMode, filePath, this.async);else this.xmlDoc.open(postMode ? "POST" : "GET", filePath, this.async);

    if (rpc) {
      this.xmlDoc.setRequestHeader("User-Agent", "dhtmlxRPC v0.1 (" + navigator.userAgent + ")");
      this.xmlDoc.setRequestHeader("Content-type", "text/xml");
    } else if (postMode) this.xmlDoc.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');

    this.xmlDoc.setRequestHeader("X-Requested-With", "XMLHttpRequest");
    this.xmlDoc.send(null || postVars);
    if (!this.async) new this.waitLoadFunction(this)();
  };
  /**
   *	 @desc: destructor, cleans used memory
  	*	 @type: private
  	*	 @topic: 0
  	*/


  dtmlXMLLoaderObject.prototype.destructor = function () {
    this._filterXPath = null;
    this._getAllNamedChilds = null;
    this._retry = null;
    this.async = null;
    this.rSeed = null;
    this.filePath = null;
    this.onloadAction = null;
    this.mainObject = null;
    this.xmlDoc = null;
    this.doXPath = null;
    this.doXPathOpera = null;
    this.doXSLTransToObject = null;
    this.doXSLTransToString = null;
    this.loadXML = null;
    this.loadXMLString = null; // this.waitLoadFunction = null;

    this.doSerialization = null;
    this.xmlNodeToJSON = null;
    this.getXMLTopNode = null;
    this.setXSLParamValue = null;
    return null;
  };

  dtmlXMLLoaderObject.prototype.xmlNodeToJSON = function (node) {
    var t = {};

    for (var i = 0; i < node.attributes.length; i++) {
      t[node.attributes[i].name] = node.attributes[i].value;
    }

    t["_tagvalue"] = node.firstChild ? node.firstChild.nodeValue : "";

    for (var i = 0; i < node.childNodes.length; i++) {
      var name = node.childNodes[i].tagName;

      if (name) {
        if (!t[name]) t[name] = [];
        t[name].push(this.xmlNodeToJSON(node.childNodes[i]));
      }
    }

    return t;
  };

  function dhtmlDragAndDropObject() {
    if (global.dhtmlDragAndDrop) return global.dhtmlDragAndDrop;
    this.lastLanding = 0;
    this.dragNode = 0;
    this.dragStartNode = 0;
    this.dragStartObject = 0;
    this.tempDOMU = null;
    this.tempDOMM = null;
    this.waitDrag = 0;
    global.dhtmlDragAndDrop = this;
    return this;
  }

  global.dhtmlDragAndDropObject = dhtmlDragAndDropObject;

  dhtmlDragAndDropObject.prototype.removeDraggableItem = function (htmlNode) {
    htmlNode.onmousedown = null;
    htmlNode.dragStarter = null;
    htmlNode.dragLanding = null;
  };

  dhtmlDragAndDropObject.prototype.addDraggableItem = function (htmlNode, dhtmlObject) {
    htmlNode.onmousedown = this.preCreateDragCopy;
    htmlNode.dragStarter = dhtmlObject;
    this.addDragLanding(htmlNode, dhtmlObject);
  };

  dhtmlDragAndDropObject.prototype.addDragLanding = function (htmlNode, dhtmlObject) {
    htmlNode.dragLanding = dhtmlObject;
  };

  dhtmlDragAndDropObject.prototype.preCreateDragCopy = function (e) {
    if ((e || global.event) && (e || event).button == 2) return;

    if (global.dhtmlDragAndDrop.waitDrag) {
      global.dhtmlDragAndDrop.waitDrag = 0;
      document.body.onmouseup = global.dhtmlDragAndDrop.tempDOMU;
      document.body.onmousemove = global.dhtmlDragAndDrop.tempDOMM;
      return false;
    }

    if (global.dhtmlDragAndDrop.dragNode) global.dhtmlDragAndDrop.stopDrag(e);
    global.dhtmlDragAndDrop.waitDrag = 1;
    global.dhtmlDragAndDrop.tempDOMU = document.body.onmouseup;
    global.dhtmlDragAndDrop.tempDOMM = document.body.onmousemove;
    global.dhtmlDragAndDrop.dragStartNode = this;
    global.dhtmlDragAndDrop.dragStartObject = this.dragStarter;
    document.body.onmouseup = global.dhtmlDragAndDrop.preCreateDragCopy;
    document.body.onmousemove = global.dhtmlDragAndDrop.callDrag;
    global.dhtmlDragAndDrop.downtime = new Date().valueOf();

    if (e && e.preventDefault) {
      e.preventDefault();
      return false;
    }

    return false;
  };

  dhtmlDragAndDropObject.prototype.callDrag = function (e) {
    if (!e) e = global.event;
    var dragger = global.dhtmlDragAndDrop;
    if (new Date().valueOf() - dragger.downtime < 100) return; //if ((e.button == 0)&&(_isIE))
    //	return dragger.stopDrag();

    if (!dragger.dragNode) {
      if (dragger.waitDrag) {
        dragger.dragNode = dragger.dragStartObject._createDragNode(dragger.dragStartNode, e);
        if (!dragger.dragNode) return dragger.stopDrag();

        dragger.dragNode.onselectstart = function () {
          return false;
        };

        dragger.gldragNode = dragger.dragNode;
        document.body.appendChild(dragger.dragNode);
        document.body.onmouseup = dragger.stopDrag;
        dragger.waitDrag = 0;
        dragger.dragNode.pWindow = global;
        dragger.initFrameRoute();
      } else return dragger.stopDrag(e, true);
    }

    if (dragger.dragNode.parentNode != global.document.body && dragger.gldragNode) {
      var grd = dragger.gldragNode;
      if (dragger.gldragNode.old) grd = dragger.gldragNode.old; //if (!document.all) dragger.calculateFramePosition();

      grd.parentNode.removeChild(grd);
      var oldBody = dragger.dragNode.pWindow;
      if (grd.pWindow && grd.pWindow.dhtmlDragAndDrop.lastLanding) grd.pWindow.dhtmlDragAndDrop.lastLanding.dragLanding._dragOut(grd.pWindow.dhtmlDragAndDrop.lastLanding); //		var oldp=dragger.dragNode.parentObject;

      if (_isIE) {
        var div = document.createElement("div");
        div.innerHTML = dragger.dragNode.outerHTML;
        dragger.dragNode = div.childNodes[0];
      } else dragger.dragNode = dragger.dragNode.cloneNode(true);

      dragger.dragNode.pWindow = global; //		dragger.dragNode.parentObject=oldp;

      dragger.gldragNode.old = dragger.dragNode;
      document.body.appendChild(dragger.dragNode);
      oldBody.dhtmlDragAndDrop.dragNode = dragger.dragNode;
    }

    dragger.dragNode.style.left = e.clientX + 15 + (dragger.fx ? dragger.fx * -1 : 0) + (document.body.scrollLeft || document.documentElement.scrollLeft) + "px";
    dragger.dragNode.style.top = e.clientY + 3 + (dragger.fy ? dragger.fy * -1 : 0) + (document.body.scrollTop || document.documentElement.scrollTop) + "px";
    var z;
    if (!e.srcElement) z = e.target;else z = e.srcElement;
    dragger.checkLanding(z, e);
  };

  dhtmlDragAndDropObject.prototype.calculateFramePosition = function (n) {
    //this.fx = 0, this.fy = 0;
    if (global.name) {
      var el = parent.frames[global.name].frameElement.offsetParent;
      var fx = 0;
      var fy = 0;

      while (el) {
        fx += el.offsetLeft;
        fy += el.offsetTop;
        el = el.offsetParent;
      }

      if (parent.dhtmlDragAndDrop) {
        var ls = parent.dhtmlDragAndDrop.calculateFramePosition(1);
        fx += ls.split('_')[0] * 1;
        fy += ls.split('_')[1] * 1;
      }

      if (n) return fx + "_" + fy;else this.fx = fx;
      this.fy = fy;
    }

    return "0_0";
  };

  dhtmlDragAndDropObject.prototype.checkLanding = function (htmlObject, e) {
    if (htmlObject && htmlObject.dragLanding) {
      if (this.lastLanding) this.lastLanding.dragLanding._dragOut(this.lastLanding);
      this.lastLanding = htmlObject;
      this.lastLanding = this.lastLanding.dragLanding._dragIn(this.lastLanding, this.dragStartNode, e.clientX, e.clientY, e);
      this.lastLanding_scr = _isIE ? e.srcElement : e.target;
    } else {
      if (htmlObject && htmlObject.tagName != "BODY") this.checkLanding(htmlObject.parentNode, e);else {
        if (this.lastLanding) this.lastLanding.dragLanding._dragOut(this.lastLanding, e.clientX, e.clientY, e);
        this.lastLanding = 0;
        if (this._onNotFound) this._onNotFound();
      }
    }
  };

  dhtmlDragAndDropObject.prototype.stopDrag = function (e, mode) {
    var dragger = global.dhtmlDragAndDrop;

    if (!mode) {
      dragger.stopFrameRoute();
      var temp = dragger.lastLanding;
      dragger.lastLanding = null;
      if (temp) temp.dragLanding._drag(dragger.dragStartNode, dragger.dragStartObject, temp, _isIE ? event.srcElement : e.target);
    }

    dragger.lastLanding = null;
    if (dragger.dragNode && dragger.dragNode.parentNode == document.body) dragger.dragNode.parentNode.removeChild(dragger.dragNode);
    dragger.dragNode = 0;
    dragger.gldragNode = 0;
    dragger.fx = 0;
    dragger.fy = 0;
    dragger.dragStartNode = 0;
    dragger.dragStartObject = 0;
    document.body.onmouseup = dragger.tempDOMU;
    document.body.onmousemove = dragger.tempDOMM;
    dragger.tempDOMU = null;
    dragger.tempDOMM = null;
    dragger.waitDrag = 0;
  };

  dhtmlDragAndDropObject.prototype.stopFrameRoute = function (win) {
    if (win) global.dhtmlDragAndDrop.stopDrag(1, 1);

    for (var i = 0; i < global.frames.length; i++) {
      try {
        if (global.frames[i] != win && global.frames[i].dhtmlDragAndDrop) global.frames[i].dhtmlDragAndDrop.stopFrameRoute(global);
      } catch (e) {}
    }

    try {
      if (parent.dhtmlDragAndDrop && parent != global && parent != win) parent.dhtmlDragAndDrop.stopFrameRoute(global);
    } catch (e) {}
  };

  dhtmlDragAndDropObject.prototype.initFrameRoute = function (win, mode) {
    if (win) {
      global.dhtmlDragAndDrop.preCreateDragCopy();
      global.dhtmlDragAndDrop.dragStartNode = win.dhtmlDragAndDrop.dragStartNode;
      global.dhtmlDragAndDrop.dragStartObject = win.dhtmlDragAndDrop.dragStartObject;
      global.dhtmlDragAndDrop.dragNode = win.dhtmlDragAndDrop.dragNode;
      global.dhtmlDragAndDrop.gldragNode = win.dhtmlDragAndDrop.dragNode;
      global.document.body.onmouseup = global.dhtmlDragAndDrop.stopDrag;
      global.waitDrag = 0;
      if (!_isIE && mode && (!_isFF || _FFrv < 1.8)) global.dhtmlDragAndDrop.calculateFramePosition();
    }

    try {
      if (parent.dhtmlDragAndDrop && parent != global && parent != win) parent.dhtmlDragAndDrop.initFrameRoute(global);
    } catch (e) {}

    for (var i = 0; i < global.frames.length; i++) {
      try {
        if (global.frames[i] != win && global.frames[i].dhtmlDragAndDrop) global.frames[i].dhtmlDragAndDrop.initFrameRoute(global, !win || mode ? 1 : 0);
      } catch (e) {}
    }
  };

  var _isFF = false;
  var _isIE = false;
  var _isOpera = false;
  var _isKHTML = false;
  var _isMacOS = false;
  var _isChrome = false;
  var _FFrv = false;
  var _KHTMLrv = false;
  var _OperaRv = false;
  if (navigator.userAgent.indexOf('Macintosh') != -1) _isMacOS = true;
  if (navigator.userAgent.toLowerCase().indexOf('chrome') > -1) _isChrome = true;

  if (navigator.userAgent.indexOf('Safari') != -1 || navigator.userAgent.indexOf('Konqueror') != -1) {
    _KHTMLrv = parseFloat(navigator.userAgent.substr(navigator.userAgent.indexOf('Safari') + 7, 5));

    if (_KHTMLrv > 525) {
      //mimic FF behavior for Safari 3.1+
      _isFF = true;
      _FFrv = 1.9;
    } else _isKHTML = true;
  } else if (navigator.userAgent.indexOf('Opera') != -1) {
    _isOpera = true;
    _OperaRv = parseFloat(navigator.userAgent.substr(navigator.userAgent.indexOf('Opera') + 6, 3));
  } else if (navigator.appName.indexOf("Microsoft") != -1) {
    _isIE = true;

    if ((navigator.appVersion.indexOf("MSIE 8.0") != -1 || navigator.appVersion.indexOf("MSIE 9.0") != -1 || navigator.appVersion.indexOf("MSIE 10.0") != -1) && document.compatMode != "BackCompat") {
      _isIE = 8;
    }
  } else if (navigator.appName == 'Netscape' && navigator.userAgent.indexOf("Trident") != -1) {
    //ie11
    _isIE = 8;
  } else {
    _isFF = true;
    _FFrv = parseFloat(navigator.userAgent.split("rv:")[1]);
  } //multibrowser Xpath processor


  dtmlXMLLoaderObject.prototype.doXPath = function (xpathExp, docObj, namespace, result_type) {
    if (_isKHTML || !_isIE && !global.XPathResult) return this.doXPathOpera(xpathExp, docObj);

    if (_isIE) {
      //IE
      if (!docObj) if (!this.xmlDoc.nodeName) docObj = this.xmlDoc.responseXML;else docObj = this.xmlDoc;
      if (!docObj) dhtmlxError.throwError("LoadXML", "Incorrect XML", [docObj || this.xmlDoc, this.mainObject]);
      if (namespace) docObj.setProperty("SelectionNamespaces", "xmlns:xsl='" + namespace + "'"); //

      if (result_type == 'single') {
        return docObj.selectSingleNode(xpathExp);
      } else {
        return docObj.selectNodes(xpathExp) || new Array(0);
      }
    } else {
      //Mozilla
      var nodeObj = docObj;

      if (!docObj) {
        if (!this.xmlDoc.nodeName) {
          docObj = this.xmlDoc.responseXML;
        } else {
          docObj = this.xmlDoc;
        }
      }

      if (!docObj) dhtmlxError.throwError("LoadXML", "Incorrect XML", [docObj || this.xmlDoc, this.mainObject]);

      if (docObj.nodeName.indexOf("document") != -1) {
        nodeObj = docObj;
      } else {
        nodeObj = docObj;
        docObj = docObj.ownerDocument;
      }

      var retType = XPathResult.ANY_TYPE;
      if (result_type == 'single') retType = XPathResult.FIRST_ORDERED_NODE_TYPE;
      var rowsCol = [];
      var col = docObj.evaluate(xpathExp, nodeObj, function (pref) {
        return namespace;
      }, retType, null);

      if (retType == XPathResult.FIRST_ORDERED_NODE_TYPE) {
        return col.singleNodeValue;
      }

      var thisColMemb = col.iterateNext();

      while (thisColMemb) {
        rowsCol[rowsCol.length] = thisColMemb;
        thisColMemb = col.iterateNext();
      }

      return rowsCol;
    }
  };

  global.dhtmlxError = new dhtmlxErrorConstructor(); //opera fake, while 9.0 not released
  //multibrowser Xpath processor

  dtmlXMLLoaderObject.prototype.doXPathOpera = function (xpathExp, docObj) {
    //this is fake for Opera
    var z = xpathExp.replace(/[\/]+/gi, "/").split('/');
    var obj = null;
    var i = 1;
    if (!z.length) return [];
    if (z[0] == ".") obj = [docObj];else if (z[0] === "") {
      obj = (this.xmlDoc.responseXML || this.xmlDoc).getElementsByTagName(z[i].replace(/\[[^\]]*\]/g, ""));
      i++;
    } else return [];

    for (i; i < z.length; i++) {
      obj = this._getAllNamedChilds(obj, z[i]);
    }

    if (z[i - 1].indexOf("[") != -1) obj = this._filterXPath(obj, z[i - 1]);
    return obj;
  };

  dtmlXMLLoaderObject.prototype._filterXPath = function (a, b) {
    var c = [];
    var b = b.replace(/[^\[]*\[\@/g, "").replace(/[\[\]\@]*/g, "");

    for (var i = 0; i < a.length; i++) {
      if (a[i].getAttribute(b)) c[c.length] = a[i];
    }

    return c;
  };

  dtmlXMLLoaderObject.prototype._getAllNamedChilds = function (a, b) {
    var c = [];
    if (_isKHTML) b = b.toUpperCase();

    for (var i = 0; i < a.length; i++) {
      for (var j = 0; j < a[i].childNodes.length; j++) {
        if (_isKHTML) {
          if (a[i].childNodes[j].tagName && a[i].childNodes[j].tagName.toUpperCase() == b) c[c.length] = a[i].childNodes[j];
        } else if (a[i].childNodes[j].tagName == b) c[c.length] = a[i].childNodes[j];
      }
    }

    return c;
  };

  function dhtmlXHeir(a, b) {
    for (var c in b) {
      if (typeof b[c] == "function") a[c] = b[c];
    }

    return a;
  }

  if (typeof global.dhtmlxEvent == 'undefined') {
    global.dhtmlxEvent = function dhtmlxEvent(el, event, handler) {
      if (el.addEventListener) el.addEventListener(event, handler, false);else if (el.attachEvent) el.attachEvent("on" + event, handler);
    };
  } //============= XSL Extension ===================================


  dtmlXMLLoaderObject.prototype.xslDoc = null;

  dtmlXMLLoaderObject.prototype.setXSLParamValue = function (paramName, paramValue, xslDoc) {
    if (!xslDoc) xslDoc = this.xslDoc;
    if (xslDoc.responseXML) xslDoc = xslDoc.responseXML;
    var item = this.doXPath("/xsl:stylesheet/xsl:variable[@name='" + paramName + "']", xslDoc, "http:/\/www.w3.org/1999/XSL/Transform", "single");
    if (item) item.firstChild.nodeValue = paramValue;
  };

  dtmlXMLLoaderObject.prototype.doXSLTransToObject = function (xslDoc, xmlDoc) {
    if (!xslDoc) xslDoc = this.xslDoc;
    if (xslDoc.responseXML) xslDoc = xslDoc.responseXML;
    if (!xmlDoc) xmlDoc = this.xmlDoc;
    if (xmlDoc.responseXML) xmlDoc = xmlDoc.responseXML;
    var result; //Mozilla

    if (!_isIE) {
      if (!this.XSLProcessor) {
        this.XSLProcessor = new XSLTProcessor();
        this.XSLProcessor.importStylesheet(xslDoc);
      }

      result = this.XSLProcessor.transformToDocument(xmlDoc);
    } else {
      result = new ActiveXObject("Msxml2.DOMDocument.3.0");

      try {
        xmlDoc.transformNodeToObject(xslDoc, result);
      } catch (e) {
        result = xmlDoc.transformNode(xslDoc);
      }
    }

    return result;
  };

  dtmlXMLLoaderObject.prototype.doXSLTransToString = function (xslDoc, xmlDoc) {
    var res = this.doXSLTransToObject(xslDoc, xmlDoc);
    if (typeof res == "string") return res;
    return this.doSerialization(res);
  };

  dtmlXMLLoaderObject.prototype.doSerialization = function (xmlDoc) {
    if (!xmlDoc) xmlDoc = this.xmlDoc;
    if (xmlDoc.responseXML) xmlDoc = xmlDoc.responseXML;

    if (!_isIE) {
      var xmlSerializer = new XMLSerializer();
      return xmlSerializer.serializeToString(xmlDoc);
    } else return xmlDoc.xml;
  };
  /**
   *   @desc:
   *   @type: private
   */


  global.dhtmlxEventable = function (obj) {
    obj.attachEvent = function (name, catcher, callObj) {
      name = 'ev_' + name.toLowerCase();
      if (!this[name]) this[name] = new this.eventCatcher(callObj || this);
      return name + ':' + this[name].addEvent(catcher); //return ID (event name & event ID)
    };

    obj.callEvent = function (name, arg0) {
      name = 'ev_' + name.toLowerCase();
      if (this[name]) return this[name].apply(this, arg0);
      return true;
    };

    obj.checkEvent = function (name) {
      return !!this['ev_' + name.toLowerCase()];
    };

    obj.eventCatcher = function (obj) {
      var dhx_catch = [];

      var z = function z() {
        var res = true;

        for (var i = 0; i < dhx_catch.length; i++) {
          if (dhx_catch[i]) {
            var zr = dhx_catch[i].apply(obj, arguments);
            res = res && zr;
          }
        }

        return res;
      };

      z.addEvent = function (ev) {
        if (typeof ev != "function") ev = eval(ev);
        if (ev) return dhx_catch.push(ev) - 1;
        return false;
      };

      z.removeEvent = function (id) {
        dhx_catch[id] = null;
      };

      return z;
    };

    obj.detachEvent = function (id) {
      if (id) {
        var list = id.split(':'); //get EventName and ID

        this[list[0]].removeEvent(list[1]); //remove event
      }
    };

    obj.detachAllEvents = function () {
      for (var name in this) {
        if (name.indexOf("ev_") === 0) {
          this.detachEvent(name);
          this[name] = null;
        }
      }
    };

    obj = null;
  };
}