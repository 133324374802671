export default {
  date: {
    month_full: ["Januari", "Februari", "Mars", "April", "Maj", "Juni", "Juli", "Augusti", "September", "Oktober", "November", "December"],
    month_short: ["Jan", "Feb", "Mar", "Apr", "Maj", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dec"],
    day_full: ["Söndag", "Måndag", "Tisdag", "Onsdag", "Torsdag", "Fredag", "Lördag"],
    day_short: ["Sön", "Mån", "Tis", "Ons", "Tor", "Fre", "Lör"]
  },
  labels: {
    dhx_cal_today_button: "Idag",
    day_tab: "Dag",
    week_tab: "Vecka",
    month_tab: "Månad",
    new_event: "Ny händelse",
    icon_save: "Spara",
    icon_cancel: "Ångra",
    icon_details: "Detaljer",
    icon_edit: "Ändra",
    icon_delete: "Ta bort",
    confirm_closing: "",
    //Dina förändingar kommer gå förlorade, är du säker?
    confirm_deleting: "Är du säker på att du vill ta bort händelsen permanent?",
    section_description: "Beskrivning",
    section_time: "Tid",
    full_day: "Hela dagen",

    /*recurring events*/
    confirm_recurring: "Vill du redigera hela serien med repeterande händelser?",
    section_recurring: "Upprepa händelse",
    button_recurring: "Inaktiverat",
    button_recurring_open: "Aktiverat",
    button_edit_series: "Redigera serien",
    button_edit_occurrence: "Redigera en kopia",

    /*agenda view extension*/
    agenda_tab: "Dagordning",
    date: "Datum",
    description: "Beskrivning",

    /*year view extension*/
    year_tab: "År",

    /*week agenda view extension*/
    week_agenda_tab: "Dagordning",

    /*grid view extension*/
    grid_tab: "Galler",

    /* touch tooltip*/
    drag_to_create: "Dra för att skapa ny",
    drag_to_move: "Dra för att flytta",

    /* dhtmlx message default buttons */
    message_ok: "OK",
    message_cancel: "Cancel",

    /* wai aria labels for non-text controls */
    next: "Next",
    prev: "Previous",
    year: "Year",
    month: "Month",
    day: "Day",
    hour: "Hour",
    minute: "Minute",

    /* recurring event components */
    repeat_radio_day: "Dagligen",
    repeat_radio_week: "Veckovis",
    repeat_radio_month: "Månadsvis",
    repeat_radio_year: "Årligen",
    repeat_radio_day_type: "Var",
    repeat_text_day_count: "dag",
    repeat_radio_day_type2: "Varje arbetsdag",
    repeat_week: " Upprepa var",
    repeat_text_week_count: "vecka dessa dagar:",
    repeat_radio_month_type: "Upprepa",
    repeat_radio_month_start: "Den",
    repeat_text_month_day: "dagen var",
    repeat_text_month_count: "månad",
    repeat_text_month_count2_before: "var",
    repeat_text_month_count2_after: "månad",
    repeat_year_label: "Den",
    select_year_day2: "i",
    repeat_text_year_day: "dag i",
    select_year_month: "månad",
    repeat_radio_end: "Inget slutdatum",
    repeat_text_occurences_count: "upprepningar",
    repeat_radio_end3: "Sluta efter",
    repeat_radio_end2: "Efter",
    month_for_recurring: ["Januari", "Februari", "Mars", "April", "Maj", "Juni", "Juli", "Augusti", "September", "Oktober", "November", "December"],
    day_for_recurring: ["Söndag", "Måndag", "Tisdag", "Onsdag", "Torsdag", "Fredag", "Lördag"]
  }
};