import virtual_events_helpers from "../core/virtual_events_helpers";
export default function (scheduler) {
  scheduler.config.multisection = true;
  scheduler.config.multisection_shift_all = true;
  scheduler.config.section_delimiter = ",";
  scheduler.attachEvent("onSchedulerReady", function () {
    virtual_events_helpers(scheduler); // #include core/virtual_events_helpers.js

    var old_unit = scheduler._update_unit_section;

    scheduler._update_unit_section = function (action) {
      return scheduler._update_sections(action, old_unit);
    };

    var old_timeline = scheduler._update_timeline_section;

    scheduler._update_timeline_section = function (action) {
      return scheduler._update_sections(action, old_timeline);
    };

    scheduler.isMultisectionEvent = function (ev) {
      if (ev && this._get_multisection_view()) {
        var units = this._get_event_sections(ev);

        return units.length > 1;
      }

      return false;
    };

    scheduler._get_event_sections = function (event) {
      var mapping = this._get_section_property();

      var units = event[mapping] || "";
      return this._parse_event_sections(units);
    };

    scheduler._parse_event_sections = function (value) {
      if (value instanceof Array) {
        return value;
      } else {
        return value.toString().split(scheduler.config.section_delimiter);
      }
    };

    scheduler._clear_copied_events();

    scheduler._split_events = function (evs) {
      var stack = [];

      var pr = this._get_multisection_view();

      var mapping = this._get_section_property();

      if (pr) {
        for (var i = 0; i < evs.length; i++) {
          var units = this._get_event_sections(evs[i]);

          if (units.length > 1) {
            for (var j = 0; j < units.length; j++) {
              if (typeof pr.order[units[j]] === "undefined") continue;

              var ev = scheduler._copy_event(evs[i]);

              ev[mapping] = units[j];
              stack.push(ev);
            }
          } else {
            stack.push(evs[i]);
          }
        }
      } else {
        stack = evs;
      }

      return stack;
    };

    scheduler._get_multisection_view = function () {
      if (!this.config.multisection) {
        return false;
      } else {
        return scheduler._get_section_view();
      }
    };

    var vis_evs = scheduler.get_visible_events;

    scheduler.get_visible_events = function (only_timed) {
      this._clear_copied_events();

      var evs = vis_evs.apply(this, arguments);

      if (this._get_multisection_view()) {
        evs = this._split_events(evs);

        for (var i = 0; i < evs.length; i++) {
          if (!this.is_visible_events(evs[i])) {
            evs.splice(i, 1);
            i--;
          }
        }

        this._register_copies_array(evs);
      }

      return evs;
    };

    scheduler._rendered_events = {};
    var old_view_data = scheduler.render_view_data;

    scheduler.render_view_data = function (evs, hold) {
      if (this._get_multisection_view() && evs) {
        //render single event during dnd, restore flags that were calculated during full render
        evs = this._split_events(evs);

        this._restore_render_flags(evs);
      }

      return old_view_data.apply(this, [evs, hold]);
    };

    scheduler._update_sections = function (action, def_handler) {
      var view = action.view,
          event = action.event,
          pos = action.pos; //	drag_single = true;
      //view - timeline or units view object. both stores displayed sections in 'view.order' hash
      // pos - mouse position, calculated in _mouse_coords method
      // event - scheduler event

      if (!scheduler.isMultisectionEvent(event)) {
        def_handler.apply(scheduler, [action]);
      } else {
        if (!scheduler._drag_event._orig_section) {
          scheduler._drag_event._orig_section = pos.section;
        }

        if (scheduler._drag_event._orig_section != pos.section) {
          var shift = view.order[pos.section] - view.order[scheduler._drag_event._orig_section];

          if (shift) {
            var sections = this._get_event_sections(event);

            var new_sections = [];
            var shifted = true;

            if (scheduler.config.multisection_shift_all) {
              for (var i = 0; i < sections.length; i++) {
                var new_section = scheduler._shift_sections(view, sections[i], shift);

                if (new_section !== null) {
                  new_sections[i] = new_section;
                } else {
                  new_sections = sections;
                  shifted = false;
                  break;
                }
              }
            } else {
              for (var i = 0; i < sections.length; i++) {
                // if section is occupied return
                if (sections[i] == pos.section) {
                  new_sections = sections;
                  shifted = false;
                  break;
                } // find and shift only one section


                if (sections[i] == scheduler._drag_event._orig_section) {
                  var new_section = scheduler._shift_sections(view, sections[i], shift);

                  if (new_section !== null) {
                    new_sections[i] = new_section;
                  } else {
                    new_sections = sections;
                    shifted = false;
                    break;
                  }
                } else {
                  new_sections[i] = sections[i];
                }
              }
            }

            if (shifted) scheduler._drag_event._orig_section = pos.section;
            event[scheduler._get_section_property()] = new_sections.join(scheduler.config.section_delimiter);
          }
        }
      }
    };

    scheduler._shift_sections = function (matrix, orig_section, shift) {
      var orig_order = null;
      var units = matrix.y_unit || matrix.options;

      for (var i = 0; i < units.length; i++) {
        if (units[i].key == orig_section) {
          orig_order = i;
          break;
        }
      }

      var new_unit = units[orig_order + shift];

      if (!new_unit) {
        return null;
      }

      return new_unit.key;
    }; // limit extension


    var old_get_blocked_zones = scheduler._get_blocked_zones;

    scheduler._get_blocked_zones = function (timespans, property, day_index, day_value, timespan_type) {
      if (property && this.config.multisection) {
        property = this._parse_event_sections(property);
        var zones = [];

        for (var i = 0; i < property.length; i++) {
          zones = zones.concat(old_get_blocked_zones.apply(this, [timespans, property[i], day_index, day_value, timespan_type]));
        }

        return zones;
      } else {
        return old_get_blocked_zones.apply(this, arguments);
      }
    }; // collision extension


    var old_check_secions_collision = scheduler._check_sections_collision;

    scheduler._check_sections_collision = function (a, b) {
      if (this.config.multisection && this._get_section_view()) {
        a = this._split_events([a]);
        b = this._split_events([b]);
        var collision = false;

        for (var a_ind = 0, a_len = a.length; a_ind < a_len; a_ind++) {
          if (collision) {
            break;
          }

          for (var b_ind = 0, b_len = b.length; b_ind < b_len; b_ind++) {
            if (old_check_secions_collision.apply(this, [a[a_ind], b[b_ind]])) {
              collision = true;
              break;
            }
          }
        }

        return collision;
      } else {
        return old_check_secions_collision.apply(this, arguments);
      }
    };
  });
}