function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

export default function (scheduler) {
  function backup(obj) {
    var t = function t() {};

    t.prototype = obj;
    return t;
  }

  var old = scheduler._load;

  scheduler._load = function (url, from) {
    url = url || this._load_url;

    if (_typeof(url) == "object") {
      var t = backup(this._loaded);

      for (var i = 0; i < url.length; i++) {
        this._loaded = new t();
        old.call(this, url[i], from);
      }
    } else old.apply(this, arguments);
  };
}