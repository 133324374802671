export default function (scheduler) {
  scheduler.$keyboardNavigation._minicalendars = [];

  scheduler.$keyboardNavigation.isMinical = function (node) {
    var minicalendars = scheduler.$keyboardNavigation._minicalendars;

    for (var i = 0; i < minicalendars.length; i++) {
      if (this.isChildOf(node, minicalendars[i])) return true;
    }

    return false;
  };

  scheduler.$keyboardNavigation.isChildOf = function (child, parent) {
    while (child && child !== parent) {
      child = child.parentNode;
    }

    return !!(child === parent);
  };

  scheduler.$keyboardNavigation.patchMinicalendar = function () {
    var dispatcher = scheduler.$keyboardNavigation.dispatcher;

    function focusMinical(e) {
      var target = e.target;
      dispatcher.enable();
      dispatcher.setActiveNode(new scheduler.$keyboardNavigation.MinicalButton(target, 0));
    }

    function minicalClick(e) {
      var target = e.target || e.srcElement;
      var prev = scheduler.utils.dom.locateCss(e, "dhx_cal_prev_button", false);
      var next = scheduler.utils.dom.locateCss(e, "dhx_cal_next_button", false);
      var cell = scheduler.utils.dom.locateCss(e, "dhx_year_body", false);
      var rowIndex = 0;
      var cellIndex = 0;

      if (cell) {
        var tr;
        var td;
        var current = target;

        while (current && current.tagName.toLowerCase() != "td") {
          current = current.parentNode;
        }

        if (current) {
          td = current;
          tr = td.parentNode;
        }

        if (tr && td) {
          var rows = tr.parentNode.querySelectorAll("tr");

          for (var i = 0; i < rows.length; i++) {
            if (rows[i] == tr) {
              rowIndex = i;
              break;
            }
          }

          var cells = tr.querySelectorAll("td");

          for (var i = 0; i < cells.length; i++) {
            if (cells[i] == td) {
              cellIndex = i;
              break;
            }
          }
        }
      }

      var root = e.currentTarget;
      dispatcher.delay(function () {
        if (prev || next || cell) {
          var element;

          if (prev) {
            element = new scheduler.$keyboardNavigation.MinicalButton(root, 0);
            dispatcher.setActiveNode(new scheduler.$keyboardNavigation.MinicalButton(root, 0));
          } else if (next) {
            element = new scheduler.$keyboardNavigation.MinicalButton(root, 1);
          } else if (cell) {
            element = new scheduler.$keyboardNavigation.MinicalCell(root, rowIndex, cellIndex);
          }

          if (element) {
            dispatcher.enable();

            if (element.isValid()) {
              dispatcher.activeNode = null;
              dispatcher.setActiveNode(element);
            }
          }
        }
      });
    }

    if (scheduler.renderCalendar) {
      var renderMinical = scheduler.renderCalendar;

      scheduler.renderCalendar = function () {
        var cal = renderMinical.apply(this, arguments);
        var minicalendars = scheduler.$keyboardNavigation._minicalendars;
        scheduler.eventRemove(cal, "click", minicalClick);
        scheduler.event(cal, "click", minicalClick);
        scheduler.eventRemove(cal, "focus", focusMinical);
        scheduler.event(cal, "focus", focusMinical);
        var added = false;

        for (var i = 0; i < minicalendars.length; i++) {
          if (minicalendars[i] == cal) {
            added = true;
            break;
          }
        }

        if (!added) minicalendars.push(cal);

        if (dispatcher.isEnabled()) {
          var node = dispatcher.getActiveNode();

          if (node && node.container == cal) {
            dispatcher.focusNode(node);
          } else {
            cal.setAttribute("tabindex", "0");
          }
        } else {
          cal.setAttribute("tabindex", "0");
        }

        return cal;
      };
    }

    if (scheduler.destroyCalendar) {
      var destroyMinical = scheduler.destroyCalendar;

      scheduler.destroyCalendar = function (cal, force) {
        cal = cal || (scheduler._def_count ? scheduler._def_count.firstChild : null);
        var res = destroyMinical.apply(this, arguments);

        if (!cal || !cal.parentNode) {
          var minicalendars = scheduler.$keyboardNavigation._minicalendars;

          for (var i = 0; i < minicalendars.length; i++) {
            if (minicalendars[i] == cal) {
              scheduler.eventRemove(minicalendars[i], "focus", focusMinical);
              minicalendars.splice(i, 1);
              i--;
            }
          }
        }

        return res;
      };
    }
  };
}