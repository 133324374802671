export default {
  date: {
    month_full: ["Leden", "Únor", "Březen", "Duben", "Květen", "Červen", "Červenec", "Srpen", "Září", "Říjen", "Listopad", "Prosinec"],
    month_short: ["Led", "Ún", "Bře", "Dub", "Kvě", "Čer", "Čec", "Srp", "Září", "Říj", "List", "Pro"],
    day_full: ["Neděle", "Pondělí", "Úterý", "Středa", "Čtvrtek", "Pátek", "Sobota"],
    day_short: ["Ne", "Po", "Út", "St", "Čt", "Pá", "So"]
  },
  labels: {
    dhx_cal_today_button: "Dnes",
    day_tab: "Den",
    week_tab: "Týden",
    month_tab: "Měsíc",
    new_event: "Nová událost",
    icon_save: "Uložit",
    icon_cancel: "Zpět",
    icon_details: "Detail",
    icon_edit: "Edituj",
    icon_delete: "Smazat",
    confirm_closing: "",
    //Vaše změny budou ztraceny, opravdu ?
    confirm_deleting: "Událost bude trvale smazána, opravdu?",
    section_description: "Poznámky",
    section_time: "Doba platnosti",

    /*recurring events*/
    confirm_recurring: "Přejete si upravit celou řadu opakovaných událostí?",
    section_recurring: "Opakování události",
    button_recurring: "Vypnuto",
    button_recurring_open: "Zapnuto",
    button_edit_series: "Edit series",
    button_edit_occurrence: "Upravit instance",

    /*agenda view extension*/
    agenda_tab: "Program",
    date: "Datum",
    description: "Poznámka",

    /*year view extension*/
    year_tab: "Rok",
    full_day: "Full day",

    /*week agenda view extension*/
    week_agenda_tab: "Program",

    /*grid view extension*/
    grid_tab: "Mřížka",

    /* touch tooltip*/
    drag_to_create: "Drag to create",
    drag_to_move: "Drag to move",

    /* dhtmlx message default buttons */
    message_ok: "OK",
    message_cancel: "Cancel",

    /* wai aria labels for non-text controls */
    next: "Next",
    prev: "Previous",
    year: "Year",
    month: "Month",
    day: "Day",
    hour: "Hour",
    minute: "Minute",

    /* recurring event components */
    repeat_radio_day: "Denně",
    repeat_radio_week: "Týdně",
    repeat_radio_month: "Měsíčně",
    repeat_radio_year: "Ročně",
    repeat_radio_day_type: "každý",
    repeat_text_day_count: "Den",
    repeat_radio_day_type2: "pracovní dny",
    repeat_week: "Opakuje každých",
    repeat_text_week_count: "Týdnů na:",
    repeat_radio_month_type: "u každého",
    repeat_radio_month_start: "na",
    repeat_text_month_day: "Den každého",
    repeat_text_month_count: "Měsíc",
    repeat_text_month_count2_before: "každý",
    repeat_text_month_count2_after: "Měsíc",
    repeat_year_label: "na",
    select_year_day2: "v",
    repeat_text_year_day: "Den v",
    select_year_month: "",
    repeat_radio_end: "bez data ukončení",
    repeat_text_occurences_count: "Události",
    repeat_radio_end2: "po",
    repeat_radio_end3: "Konec",
    month_for_recurring: ["Leden", "Únor", "Březen", "Duben", "Květen", "Červen", "Červenec", "Srpen", "Září", "Říjen", "Listopad", "Prosinec"],
    day_for_recurring: ["Neděle ", "Pondělí", "Úterý", "Středa", "Čtvrtek", "Pátek", "Sobota"]
  }
};