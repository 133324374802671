export default {
  date: {
    month_full: ["Januar", "Februar", "Marts", "April", "Maj", "Juni", "Juli", "August", "September", "Oktober", "November", "December"],
    month_short: ["Jan", "Feb", "Mar", "Apr", "Maj", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dec"],
    day_full: ["Søndag", "Mandag", "Tirsdag", "Onsdag", "Torsdag", "Fredag", "Lørdag"],
    day_short: ["Søn", "Man", "Tir", "Ons", "Tor", "Fre", "Lør"]
  },
  labels: {
    dhx_cal_today_button: "Idag",
    day_tab: "Dag",
    week_tab: "Uge",
    month_tab: "Måned",
    new_event: "Ny begivenhed",
    icon_save: "Gem",
    icon_cancel: "Fortryd",
    icon_details: "Detaljer",
    icon_edit: "Tilret",
    icon_delete: "Slet",
    confirm_closing: "Dine rettelser vil gå tabt.. Er dy sikker?",
    //Your changes will be lost, are your sure ?
    confirm_deleting: "Bigivenheden vil blive slettet permanent. Er du sikker?",
    section_description: "Beskrivelse",
    section_time: "Tidsperiode",

    /*recurring events*/
    confirm_recurring: "Vil du tilrette hele serien af gentagne begivenheder?",
    section_recurring: "Gentag begivenhed",
    button_recurring: "Frakoblet",
    button_recurring_open: "Tilkoblet",
    button_edit_series: "Rediger serien",
    button_edit_occurrence: "Rediger en kopi",

    /*agenda view extension*/
    agenda_tab: "Dagsorden",
    date: "Dato",
    description: "Beskrivelse",

    /*year view extension*/
    year_tab: "År",

    /*week agenda view extension*/
    week_agenda_tab: "Dagsorden",

    /*grid view extension*/
    grid_tab: "Grid",

    /* touch tooltip*/
    drag_to_create: "Drag to create",
    drag_to_move: "Drag to move",

    /* dhtmlx message default buttons */
    message_ok: "OK",
    message_cancel: "Cancel",

    /* wai aria labels for non-text controls */
    next: "Next",
    prev: "Previous",
    year: "Year",
    month: "Month",
    day: "Day",
    hour: "Hour",
    minute: "Minute",

    /* recurring event components */
    repeat_radio_day: "Daglig",
    repeat_radio_week: "Ugenlig",
    repeat_radio_month: "Månedlig",
    repeat_radio_year: "Årlig",
    repeat_radio_day_type: "Hver",
    repeat_text_day_count: "dag",
    repeat_radio_day_type2: "På hver arbejdsdag",
    repeat_week: " Gentager sig hver",
    repeat_text_week_count: "uge på følgende dage:",
    repeat_radio_month_type: "Hver den",
    repeat_radio_month_start: "Den",
    repeat_text_month_day: " i hver",
    repeat_text_month_count: "måned",
    repeat_text_month_count2_before: "hver",
    repeat_text_month_count2_after: "måned",
    repeat_year_label: "Den",
    select_year_day2: "i",
    repeat_text_year_day: "dag i",
    select_year_month: "",
    repeat_radio_end: "Ingen slutdato",
    repeat_text_occurences_count: "gentagelse",
    repeat_radio_end2: "Efter",
    repeat_radio_end3: "Slut",
    month_for_recurring: ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"],
    day_for_recurring: ["Søndag", "Mandag", "Tirsdag", "Onsdag", "Torsdag", "Fredag", "Lørdag"]
  }
};