//returns position of html element on the page
function elementPosition(elem) {
  var top = 0,
      left = 0,
      right = 0,
      bottom = 0;

  if (elem.getBoundingClientRect) {
    //HTML5 method
    var box = elem.getBoundingClientRect();
    var body = document.body;
    var docElem = document.documentElement || document.body.parentNode || document.body;
    var scrollTop = window.pageYOffset || docElem.scrollTop || body.scrollTop;
    var scrollLeft = window.pageXOffset || docElem.scrollLeft || body.scrollLeft;
    var clientTop = docElem.clientTop || body.clientTop || 0;
    var clientLeft = docElem.clientLeft || body.clientLeft || 0;
    top = box.top + scrollTop - clientTop;
    left = box.left + scrollLeft - clientLeft;
    right = document.body.offsetWidth - box.right;
    bottom = document.body.offsetHeight - box.bottom;
  } else {
    //fallback to naive approach
    while (elem) {
      top = top + parseInt(elem.offsetTop, 10);
      left = left + parseInt(elem.offsetLeft, 10);
      elem = elem.offsetParent;
    }

    right = document.body.offsetWidth - elem.offsetWidth - left;
    bottom = document.body.offsetHeight - elem.offsetHeight - top;
  }

  return {
    y: Math.round(top),
    x: Math.round(left),
    width: elem.offsetWidth,
    height: elem.offsetHeight,
    right: Math.round(right),
    bottom: Math.round(bottom)
  };
}
/*
event position relatively to DOM element
 */


function getRelativeEventPosition(ev, node) {
  var d = document.documentElement;
  var box = elementPosition(node);
  return {
    x: ev.clientX + d.scrollLeft - d.clientLeft - box.x + node.scrollLeft,
    y: ev.clientY + d.scrollTop - d.clientTop - box.y + node.scrollTop
  };
}

function getClassName(node) {
  if (!node) return "";
  var className = node.className || "";
  if (className.baseVal) //'className' exist but not a string - IE svg element in DOM
    className = className.baseVal;
  if (!className.indexOf) className = '';
  return className || "";
}

function locateCss(e, classname, strict) {
  if (strict === undefined) strict = true;
  var trg = e.target || e.srcElement;
  var css = '';

  while (trg) {
    css = getClassName(trg);

    if (css) {
      var ind = css.indexOf(classname);

      if (ind >= 0) {
        if (!strict) return trg; //check that we have exact match

        var left = ind === 0 || !(css.charAt(ind - 1) || "").trim();
        var right = ind + classname.length >= css.length || !css.charAt(ind + classname.length).trim();
        if (left && right) return trg;
      }
    }

    trg = trg.parentNode;
  }

  return null;
} // get focusable nodes


function isVisible(node) {
  var display = false,
      visibility = false;

  if (window.getComputedStyle) {
    var style = window.getComputedStyle(node, null);
    display = style["display"];
    visibility = style["visibility"];
  } else if (node.currentStyle) {
    display = node.currentStyle["display"];
    visibility = node.currentStyle["visibility"];
  }

  var hiddenSection = false;
  var recurringSection = locateCss({
    target: node
  }, "dhx_form_repeat", false);

  if (recurringSection) {
    hiddenSection = !!(recurringSection.style.height == "0px");
  }

  hiddenSection = hiddenSection || !node.offsetHeight;
  return display != "none" && visibility != "hidden" && !hiddenSection;
}

function hasNonNegativeTabIndex(node) {
  return !isNaN(node.getAttribute("tabindex")) && node.getAttribute("tabindex") * 1 >= 0;
}

function hasHref(node) {
  var canHaveHref = {
    "a": true,
    "area": true
  };

  if (canHaveHref[node.nodeName.loLowerCase()]) {
    return !!node.getAttribute("href");
  }

  return true;
}

function isEnabled(node) {
  var canDisable = {
    "input": true,
    "select": true,
    "textarea": true,
    "button": true,
    "object": true
  };

  if (canDisable[node.nodeName.toLowerCase()]) {
    return !node.hasAttribute("disabled");
  }

  return true;
}

function getFocusableNodes(root) {
  var nodes = root.querySelectorAll(["a[href]", "area[href]", "input", "select", "textarea", "button", "iframe", "object", "embed", "[tabindex]", "[contenteditable]"].join(", "));
  var nodesArray = Array.prototype.slice.call(nodes, 0);

  for (var i = 0; i < nodesArray.length; i++) {
    nodesArray[i].$position = i; // we remember original nodes order, 
    // so when we sort them by tabindex we ensure order of nodes with same tabindex is preserved, 
    // since some browsers do unstable sort
  }

  nodesArray.sort(function (a, b) {
    if (a.tabIndex === 0 && b.tabIndex !== 0) {
      return 1;
    }

    if (a.tabIndex !== 0 && b.tabIndex === 0) {
      return -1;
    }

    if (a.tabIndex === b.tabIndex) {
      // ensure we do stable sort
      return a.$position - b.$position;
    }

    if (a.tabIndex < b.tabIndex) {
      return -1;
    }

    return 1;
  });

  for (var i = 0; i < nodesArray.length; i++) {
    var node = nodesArray[i];
    var isValid = (hasNonNegativeTabIndex(node) || isEnabled(node) || hasHref(node)) && isVisible(node);

    if (!isValid) {
      nodesArray.splice(i, 1);
      i--;
    }
  }

  return nodesArray;
}

function isShadowDomSupported() {
  return document.head.createShadowRoot || document.head.attachShadow;
}
/**
 * Returns element that has the browser focus, or null if no element has focus.
 * Works with shadow DOM, so it's prefereed to use this function instead of document.activeElement directly.
 * @returns HTMLElement
 */


function getActiveElement() {
  var activeElement = document.activeElement;

  if (activeElement.shadowRoot) {
    activeElement = activeElement.shadowRoot.activeElement;
  }

  if (activeElement === document.body && document.getSelection) {
    activeElement = document.getSelection().focusNode || document.body;
  }

  return activeElement;
}
/**
 * Returns document.body or the host node of the ShadowRoot, if the element is attached to ShadowDom
 * @param {HTMLElement} element 
 * @returns HTMLElement
 */


function getRootNode(element) {
  if (!element) {
    return document.body;
  }

  if (!isShadowDomSupported()) {
    return document.body;
  }

  while (element.parentNode && (element = element.parentNode)) {
    if (element instanceof ShadowRoot) {
      return element.host;
    }
  }

  return document.body;
}

function hasShadowParent(element) {
  return !!getRootNode(element);
}

export default {
  /**
   *     @desc: Calculate absolute position of html object
   *     @type: private
   *     @param: htmlObject - html object
   *     @topic: 0
   */
  getAbsoluteLeft: function getAbsoluteLeft(htmlObject) {
    return this.getOffset(htmlObject).left;
  },

  /**
   *     @desc: Calculate absolute position of html object
   *     @type: private
   *     @param: htmlObject - html object
   *     @topic: 0
   */
  getAbsoluteTop: function getAbsoluteTop(htmlObject) {
    return this.getOffset(htmlObject).top;
  },
  getOffsetSum: function getOffsetSum(elem) {
    var top = 0,
        left = 0;

    while (elem) {
      top = top + parseInt(elem.offsetTop);
      left = left + parseInt(elem.offsetLeft);
      elem = elem.offsetParent;
    }

    return {
      top: top,
      left: left
    };
  },
  getOffsetRect: function getOffsetRect(elem) {
    var box = elem.getBoundingClientRect();
    var top = 0,
        left = 0; // https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent#Mobile_Tablet_or_Desktop

    if (!/Mobi/.test(navigator.userAgent)) {
      var body = document.body;
      var docElem = document.documentElement;
      var scrollTop = window.pageYOffset || docElem.scrollTop || body.scrollTop;
      var scrollLeft = window.pageXOffset || docElem.scrollLeft || body.scrollLeft;
      var clientTop = docElem.clientTop || body.clientTop || 0;
      var clientLeft = docElem.clientLeft || body.clientLeft || 0;
      top = box.top + scrollTop - clientTop;
      left = box.left + scrollLeft - clientLeft;
    } else {
      // incorrect left coordinate on mobile zoom
      // https://bugs.chromium.org/p/chromium/issues/detail?id=489206
      var dummy = document.createElement("div");
      dummy.style.position = "absolute";
      dummy.style.left = "0px";
      dummy.style.top = "0px";
      dummy.style.width = "1px";
      dummy.style.height = "1px";
      document.body.appendChild(dummy);
      var dummyBox = dummy.getBoundingClientRect();
      top = box.top - dummyBox.top;
      left = box.left - dummyBox.left;
      dummy.parentNode.removeChild(dummy);
    }

    return {
      top: Math.round(top),
      left: Math.round(left)
    };
  },
  getOffset: function getOffset(elem) {
    if (elem.getBoundingClientRect) {
      return this.getOffsetRect(elem);
    } else {
      return this.getOffsetSum(elem);
    }
  },
  closest: function closest(element, selector) {
    if (!element || !selector) {
      return null;
    }

    return _closest(element, selector);
  },
  insertAfter: function insertAfter(newNode, referenceNode) {
    if (referenceNode.nextSibling) {
      referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
    } else {
      referenceNode.parentNode.appendChild(newNode);
    }
  },
  remove: function remove(node) {
    if (node && node.parentNode) {
      node.parentNode.removeChild(node);
    }
  },
  getFocusableNodes: getFocusableNodes,
  getClassName: getClassName,
  locateCss: locateCss,
  getRootNode: getRootNode,
  hasShadowParent: hasShadowParent,
  isShadowDomSupported: isShadowDomSupported,
  getActiveElement: getActiveElement,
  getRelativeEventPosition: getRelativeEventPosition
};

var _closest;

if (Element.prototype.closest) {
  _closest = function _closest(element, selector) {
    return element.closest(selector);
  };
} else {
  var matches = Element.prototype.matches || Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;

  _closest = function _closest(element, selector) {
    var el = element;

    do {
      if (matches.call(el, selector)) {
        return el;
      }

      el = el.parentElement || el.parentNode;
    } while (el !== null && el.nodeType === 1);

    return null;
  };
}